import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  Typography,
  useMediaQuery
} from '@mui/material';
import { DialogConfirm } from 'app/components/elements/DialogConfirm';
import { ImageUser } from 'app/components/elements/ImageUser';
import { InputCustom } from 'app/components/elements/InputCustom';
import { TextError } from 'app/components/elements/TextError';
import { ROLES } from 'app/pages/Introduction';
import { useLoading } from 'contexts/loading';
import { useAuth } from 'contexts/user';
import { trim } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { regexEmail } from 'constants/regex';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { InputSelect } from 'app/components/elements/InputSelect';
import { renderListSubject } from 'constants/renderListSubject';
import { InputSelectMultiple } from 'app/components/elements/InputSelectMultiple';
import { SetStateAction, useCallback, useMemo, useRef, useState } from 'react';
import { ProfileUser } from 'app/pages/ProfilePage';
import { PrimaryButton } from 'app/components/elements/ButtonCustom';

type FormProfileProps = {
  initValue: ProfileUser;
  handleChangeProfile: (value: ProfileUser, isLogout?: boolean) => void;
};

export const FormProfile = ({ initValue, handleChangeProfile }: FormProfileProps) => {
  const { user } = useAuth();
  const { loading } = useLoading();
  const [showPassword, setShowPassword] = useState<boolean>(true);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(true);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [valueKeyWord, setValueWord] = useState<string>('');

  const [fileImage, setFileImage] = useState<string>(initValue.avatar);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const isMobile = useMediaQuery('(max-width:900px)');
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    getValues,
    reset,
    watch,
    setError,
    clearErrors,
    control
  } = useForm<ProfileUser>({
    defaultValues: initValue
  });
  const isStudent = useMemo(() => {
    return user?.role === ROLES.STUDENT;
  }, [user]);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      setValue('avatarFile', fileList[0]);
      const urlConvert = URL.createObjectURL(fileList[0]);
      setFileImage(urlConvert);
    }
  };
  const handleChooseFile = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);
  const handleSubmitModal = useCallback(
    (isLogout?: boolean) => {
      const data = getValues();
      handleChangeProfile(data, isLogout);
    },
    [getValues]
  );

  const submitForm = useCallback(
    (data: ProfileUser) => {
      if (data.email !== initValue.email) {
        setIsOpenModal(true);
        return;
      }
      handleSubmitModal();
    },
    [initValue.email]
  );

  const keywords = watch('keywords');
  const handleKeyPress = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      if (event.key === 'Enter' && valueKeyWord) {
        event.preventDefault();
        if (keywords && keywords.includes(trim(valueKeyWord))) {
          setError('keywords', { message: 'Value already exists.' });
          return;
        }
        setValueWord('');
        clearErrors('keywords');
        const newListKey = keywords ? [...keywords, valueKeyWord] : [valueKeyWord];
        setValue('keywords', newListKey);
      }
    },
    [keywords, valueKeyWord]
  );
  const deleteKey = (key: string) => {
    const newListKey = keywords?.filter((keyOld) => keyOld !== key);
    setValue('keywords', newListKey);
  };
  return (
    <Stack>
      <WrapperHeader gap={2.5} mb={5}>
        <Stack
          mt={3.5}
          minWidth={'215px'}
          maxWidth={'215px'}
          width={'100%'}
          height={'215px'}
          alignItems={'center'}
          justifyContent={'center'}>
          <ImageUser isCircle url={fileImage || ''} subjects={[{ id: '1', name: 'asda' }]} />
        </Stack>
        <ButtonStyled onClick={handleChooseFile}>
          <input
            accept={'image/*'}
            ref={fileInputRef}
            type="file"
            hidden
            onChange={handleFileChange}
          />
          <Typography color={'#fff'} fontSize={'20px'} fontWeight={700} lineHeight={'normal'}>
            Upload Photo
          </Typography>
        </ButtonStyled>
      </WrapperHeader>
      <Stack>
        <Stack>
          <Stack width={'100%'} gap={{ xs: 1, md: 10 }} flexDirection={{ xs: 'column', md: 'row' }}>
            <Stack flex={1} gap={1}>
              <Stack gap={1}>
                <InputCustom
                  colorlabel={'#697D21'}
                  label={'Name'}
                  inputProps={{
                    ...register('name', {
                      required: 'Required Field'
                    }),
                    disabled: true,
                    placeholder: 'Enter your name',
                    style: { width: isMobile ? '100%' : '481px' }
                  }}
                />
                <TextError text={errors?.name?.message || ''} />
              </Stack>
              <Stack gap={1}>
                <InputCustom
                  colorlabel={'#697D21'}
                  label={'Country'}
                  inputProps={{
                    ...register('country'),
                    disabled: true,
                    style: { width: isMobile ? '100%' : '481px' }
                  }}
                />
                <TextError text={errors?.country?.message || ''} />
              </Stack>
              <Stack gap={1}>
                <InputCustom
                  colorlabel={'#697D21'}
                  label={'City'}
                  inputProps={{
                    ...register('city'),
                    disabled: true,
                    style: { width: isMobile ? '100%' : '481px' }
                  }}
                />
                <TextError text={errors?.city?.message || ''} />
              </Stack>
              <Stack width={'100%'} gap={1}>
                <Stack>
                  <InputCustom
                    colorlabel={'#697D21'}
                    label={'Birthday'}
                    inputProps={{
                      ...register('birthday'),
                      disabled: true,
                      style: { width: isMobile ? '100%' : '481px' }
                    }}
                  />
                </Stack>
                <TextError text={errors?.birthday?.message || ''} />
              </Stack>
            </Stack>
            <Stack flex={1} gap={1}>
              <Stack gap={1}>
                <InputCustom
                  colorlabel={'#697D21'}
                  label={'Email'}
                  inputProps={{
                    ...register('email', {
                      required: 'Required Field',
                      pattern: {
                        value: regexEmail,
                        message: 'Email invalid'
                      }
                    }),
                    autoComplete: 'false',
                    name: '_email',
                    placeholder: 'Enter your email address',
                    style: { width: isMobile ? '100%' : '481px' }
                  }}
                />
                <TextError text={errors?.email?.message || ''} />
              </Stack>
              <Stack gap={1}>
                <InputCustom
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  colorlabel={'#697D21'}
                  label={'Current Password'}
                  inputProps={{
                    ...register('currentPassword', {
                      required: {
                        value: !!getValues('newPassword') && !getValues('currentPassword'),
                        message: 'Required Field'
                      },
                      minLength: {
                        value: 8,
                        message: 'Password invalid'
                      }
                    }),
                    autoComplete: `off`,
                    placeholder: 'Enter your password',
                    style: { width: isMobile ? '100%' : '481px' }
                  }}
                  type={showPassword ? 'text' : 'password'}
                />
                <TextError text={errors?.currentPassword?.message || ''} />
              </Stack>
              <Stack gap={1}>
                <InputCustom
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowNewPassword(!showNewPassword)}>
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  colorlabel={'#697D21'}
                  label={'New Password'}
                  inputProps={{
                    ...register('newPassword', {
                      required: {
                        value: !!getValues('currentPassword') && !getValues('newPassword'),
                        message: 'Required Field'
                      },
                      minLength: {
                        value: 8,
                        message: 'Password invalid'
                      }
                    }),
                    placeholder: 'Enter your password',
                    style: { width: isMobile ? '100%' : '481px' }
                  }}
                  type={showNewPassword ? 'text' : 'password'}
                />
                <TextError text={errors?.newPassword?.message || ''} />
              </Stack>
              <Stack gap={2}>
                <InputCustom
                  colorlabel={'#697D21'}
                  label={'What are you interested in?'}
                  inputProps={{
                    disabled: (keywords?.length && keywords.length >= 5) || false,
                    value: valueKeyWord,
                    onChange: (e: { target: { value: SetStateAction<string> } }) => {
                      clearErrors('keywords');
                      setValueWord(e.target.value);
                    },
                    placeholder: 'Type a keyword and press enter (maximum 5)',
                    onKeyDown: handleKeyPress,
                    style: { width: isMobile ? '100%' : '481px' }
                  }}
                />
                {errors?.keywords?.message && <TextError text={errors?.keywords?.message} />}
                <Stack
                  flexDirection={'row'}
                  gap={2}
                  mb={keywords?.length ? 1 : 0}
                  flexWrap={'wrap'}>
                  {keywords?.length !== 0 &&
                    keywords?.map((key, index) => (
                      <Stack
                        style={{
                          background: '#B4E465',
                          padding: '8px 10px',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '5px',
                          position: 'relative'
                        }}
                        key={index}>
                        <Typography fontSize={'16px'} style={{ color: '#000' }}>
                          {key}
                        </Typography>
                        <Button
                          onClick={() => deleteKey(key)}
                          style={{
                            minWidth: '20px',
                            padding: 0,
                            position: 'absolute',
                            top: -8,
                            right: -8,
                            background: '#fff',
                            borderRadius: 9
                          }}>
                          <HighlightOffIcon fontSize={'small'} />
                        </Button>
                      </Stack>
                    ))}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack width={'100%'} gap={{ xs: 1, md: 10 }} flexDirection={{ xs: 'column', md: 'row' }}>
            {!isStudent && (
              <Stack flex={1} gap={1}>
                <Stack gap={1}>
                  <Controller
                    rules={{
                      required: 'Required Field'
                    }}
                    render={({ field }) => (
                      <InputCustom
                        colorlabel={'#697D21'}
                        label={'School'}
                        inputProps={{
                          ...field,
                          placeholder: 'Enter your school',
                          style: { width: isMobile ? '100%' : '481px' }
                        }}
                      />
                    )}
                    name="school"
                    control={control}
                  />
                  <TextError text={errors.school?.message || ''} />
                </Stack>
                <Stack gap={1}>
                  <Controller
                    rules={{
                      required: 'Required Field'
                    }}
                    render={({ field }) => (
                      <InputSelect
                        colorlabel={'#697D21'}
                        label={'Major'}
                        inputProps={{
                          ...field,
                          style: {
                            width: isMobile ? '100%' : '480px',
                            padding: '0 20px 0 20px',
                            borderRadius: '12px'
                          },
                          color: '#686868'
                        }}
                        placeholder={'Select major'}
                        options={renderListSubject()}
                      />
                    )}
                    name="major"
                    control={control}
                  />
                  <TextError text={errors.major?.message || ''} />
                </Stack>
              </Stack>
            )}
            <Stack flex={1} gap={1}>
              <Stack gap={1} pr={0.5} width={isMobile ? '100%' : '480px'}>
                <Controller
                  rules={{
                    required: 'Required Field'
                  }}
                  render={({ field }) => (
                    <InputSelectMultiple
                      colorlabel={'#697D21'}
                      placeholder={'Select subject'}
                      label={'Favorite Subject(s)'}
                      options={renderListSubject()}
                      inputProps={{
                        ...field,
                        defaultValue: [],
                        style: { width: isMobile ? '100%' : '480px', borderRadius: '12px' },
                        color: '#686868'
                      }}
                    />
                  )}
                  name="subjects"
                  control={control}
                />
                <TextError text={errors.subjects?.message || ''} />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            flexDirection={'row'}
            width={'100%'}
            justifyContent={'flex-end'}
            gap={2.5}
            pb={2}
            pr={1}>
            <PrimaryButton
              isOutline
              label={'Cancel'}
              onClick={() => reset(initValue)}
              sx={{ width: 170 }}
            />
            <PrimaryButton
              isLoading={loading}
              isIconFlower
              label={'Update'}
              onClick={handleSubmit(submitForm)}
              sx={{ width: 170 }}
            />
          </Stack>
          <DialogConfirm
            isOpen={isOpenModal}
            textButton={'Reload'}
            textTitle={'Email Changed'}
            textContent={'Please reload and sign in again with your new email'}
            onSubmit={() => handleSubmitModal(true)}
            onClose={() => {
              setIsOpenModal(false);
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
const WrapperHeader = styled(Stack)(() => ({
  borderRadius: '20px',
  border: '1px solid #D090D7',
  backgroundImage: 'linear-gradient(180deg, #FFF 0%, #FF2E86 0.01%, #FF8D1A 100%)',
  boxShadow: '0px 8px 15.2px 0px rgba(119, 11, 161, 0.05)',
  height: '342px',
  width: '100%',
  alignItems: 'center'
}));
const ButtonStyled = styled(Button)(() => ({
  borderRadius: '10px',
  background: '#770BA1 !important',
  width: 178,
  height: 40
}));

import { useAuth } from 'contexts/user';
import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

type LayoutProps = {
  children: ReactNode;
  roles: string[];
};
export const LayoutRole = ({ children, roles }: LayoutProps) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (!user?.role) return;
    if (!roles.includes(user.role)) {
      navigate('/');
    }
  }, [user]);

  return <>{children}</>;
};

import { IconButton, InputAdornment, Stack, Typography, useMediaQuery } from '@mui/material';
import { useLoading } from 'contexts/loading';
import { useForm } from 'react-hook-form';
import { InputCustom } from 'app/components/elements/InputCustom';
import { Link } from 'app/components/elements/Link';
import { PrimaryButton } from 'app/components/elements/ButtonCustom';
import { useCallback, useState } from 'react';
import { isEmpty } from 'lodash';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { regexEmail } from 'constants/regex';

export type FormLoginType = {
  email: string;
  password: string;
};

type FormLoginProps = {
  onSubmit: (data: FormLoginType) => void;
};
export const FormLogin = ({ onSubmit }: FormLoginProps) => {
  const { loading } = useLoading();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const isMobile = useMediaQuery('(max-width:900px)');

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<FormLoginType>({});
  const handleSubmitForm = useCallback(
    (data: FormLoginType) => {
      if (!isEmpty(errors)) return;
      onSubmit(data);
    },
    [errors, onSubmit]
  );
  return (
    <form onSubmit={handleSubmit((data) => handleSubmitForm(data))}>
      <Stack gap={2.5}>
        <Stack gap={1}>
          <InputCustom
            label={'Email'}
            inputProps={{
              ...register('email', {
                required: 'Required Field',
                pattern: {
                  value: regexEmail,
                  message: 'Email invalid'
                }
              }),
              placeholder: 'Enter your email address',
              style: { width: isMobile ? '100%' : '403px' }
            }}
          />
          <Typography color="red" fontSize={'16px'} fontWeight="normal" height={'24px'} ml={1}>
            {errors?.email?.message}
          </Typography>
        </Stack>
        <Stack gap={1}>
          <InputCustom
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label={'Password'}
            inputProps={{
              ...register('password', {
                required: 'Required Field',
                minLength: {
                  value: 8,
                  message: 'Password invalid'
                }
              }),
              placeholder: 'Enter your password',
              style: { width: isMobile ? '100%' : '403px' }
            }}
            type={showPassword ? 'text' : 'password'}
          />
          <Typography color="red" fontSize={'16px'} fontWeight="normal" height={'24px'} ml={1}>
            {errors?.password?.message}
          </Typography>
        </Stack>

        <Typography color={'#770BA1'} lineHeight={'23px'} fontWeight={400}>
          Forgot your
          <Link to={'/forgot-password'} style={{ fontWeight: 700, marginLeft: '4px' }}>
            Password?
          </Link>
        </Typography>
        <PrimaryButton
          isLoading={loading}
          type={'submit'}
          label={'LOGIN'}
          fontSizeText={'20px'}
          isIconFlower
          sx={{ borderRadius: '12px' }}
        />
        <Typography
          mt={'40px'}
          textAlign={'center'}
          color="#770BA1"
          fontSize={'20px'}
          fontWeight={700}
          height={'normal'}>
          <Link to={'/sign-up'}>Create Account</Link>
        </Typography>
      </Stack>
    </form>
  );
};

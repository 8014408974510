import dayjs from 'dayjs';

export const checkAgeIsUnder18 = (birthDate: Date | string) => {
  const currentDate = dayjs();

  const inputDate = dayjs(birthDate);

  const age = currentDate.diff(inputDate, 'year');
  return age < 18;
};

import { Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

type CountdownTimerProps = {
  creationDate?: Date | string;
};

const TOTAL_HOURS = 48;

export const CountdownTimer: React.FC<CountdownTimerProps> = ({ creationDate }) => {
  const [timeLeft, setTimeLeft] = useState<string>();
  useEffect(() => {
    const calculateTimeLeft = () => {
      if (!creationDate) return;
      const now = dayjs();
      const created = dayjs(creationDate);
      const totalDuration = dayjs.duration(TOTAL_HOURS, 'hours');
      const elapsedTime = now.diff(created);
      const remainingTime = totalDuration.asMilliseconds() - elapsedTime;
      if (remainingTime <= 0) {
        return;
      }

      const duration = dayjs.duration(remainingTime);
      const hours = Math.floor(duration.asHours()).toString().padStart(2, '0');
      const minutes = duration.minutes().toString().padStart(2, '0');
      const seconds = duration.seconds().toString().padStart(2, '0');
      return `${hours}:${minutes}:${seconds}`;
    };

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    setTimeLeft(calculateTimeLeft());

    return () => clearInterval(timer);
  }, [creationDate]);

  return (
    <Typography
      fontWeight={700}
      fontSize={timeLeft ? '36px' : '20px'}
      color={'#F00'}
      lineHeight={'normal'}>
      {timeLeft || 'no response in 48 hours'}
    </Typography>
  );
};

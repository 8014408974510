import { yupResolver } from '@hookform/resolvers/yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, Stack, Typography, useMediaQuery } from '@mui/material';
import { useFlash } from 'contexts/flash';
import { useLoading } from 'contexts/loading';
import { useForm } from 'react-hook-form';
import { InputCustom } from 'app/components/elements/InputCustom';
import { PrimaryButton } from 'app/components/elements/ButtonCustom';
import { Link } from 'app/components/elements/Link';
import { useCallback, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { resetPasswordSchema } from 'utils/validate';

type ResetPassword = {
  password: string;
  confirmPassword: string;
};

export const ResetPasswordForm = () => {
  const [searchParams] = useSearchParams();
  const isMobile = useMediaQuery('(max-width:900px)');
  const navigate = useNavigate();
  const { loading } = useLoading();
  const { setFlash } = useFlash();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<ResetPassword>({ resolver: yupResolver(resetPasswordSchema) });
  const handleSubmitForm = useCallback(
    async (data: ResetPassword) => {
      const token = searchParams.get('token');
      if (!searchParams.get('token')) return;
      await axios.post('auth/reset-password', {
        password: data.password,
        token
      });
      setFlash({ type: 'success', message: 'Reset Password successfully' });
      navigate('/login');
    },
    [searchParams.get('token')]
  );
  const { asyncCallback: onSubmit } = useAsyncCallback(handleSubmitForm, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2.5}>
        <Stack gap={1}>
          <InputCustom
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label={'Password'}
            inputProps={{
              ...register('password'),
              placeholder: 'Enter your password',
              style: { width: isMobile ? '100%' : '403px' }
            }}
            type={showPassword ? 'text' : 'password'}
          />
          <Typography color="red" fontSize={'16px'} fontWeight="normal" height={'24px'} ml={1}>
            {errors?.password?.message}
          </Typography>
        </Stack>
        <Stack gap={1}>
          <InputCustom
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label={'Confirm Password'}
            inputProps={{
              ...register('confirmPassword'),
              placeholder: 'Enter your password',
              style: { width: isMobile ? '100%' : '403px' }
            }}
            type={showConfirmPassword ? 'text' : 'password'}
          />
          <Typography color="red" fontSize={'16px'} fontWeight="normal" height={'24px'} ml={1}>
            {errors?.confirmPassword?.message}
          </Typography>
        </Stack>
        <PrimaryButton
          isLoading={loading}
          type={'submit'}
          label={'RESET PASSWORD'}
          fontSizeText={'20px'}
          isIconFlower
          sx={{ borderRadius: '12px' }}
        />
        <Typography
          mt={{ sm: '90px', xs: '150px' }}
          textAlign={'center'}
          color="#770BA1"
          fontSize={'20px'}
          fontWeight={700}
          height={'normal'}>
          <Link to={'/login'}>Login</Link>
        </Typography>
      </Stack>
    </form>
  );
};

import { renderColorSubject } from 'utils/renderColorSubject';

type IconArrowProps = {
  subjectId: string;
};
export const IconFolder = ({ subjectId }: IconArrowProps) => (
  <svg width="61" height="48" viewBox="0 0 61 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.1 48C4.4225 48 2.98697 47.413 1.7934 46.239C0.599833 45.065 0.00203333 43.652 0 42V6C0 4.35 0.5978 2.938 1.7934 1.764C2.989 0.589999 4.42453 0.002 6.1 0H24.4L30.5 6H54.9C56.5775 6 58.014 6.588 59.2096 7.764C60.4052 8.94 61.002 10.352 61 12V42C61 43.65 60.4032 45.063 59.2096 46.239C58.0161 47.415 56.5795 48.002 54.9 48H6.1Z"
      fill={renderColorSubject(subjectId)?.color}
    />
  </svg>
);

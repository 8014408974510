import { Button, ButtonProps, Stack, styled, Typography } from '@mui/material';
import { Loading } from 'app/components/elements/Loading';
import { ReactElement } from 'react';
import { IconButtonFlower } from 'app/components/elements/icons/IconButtonFlower';
import { StyledParams } from 'types/common';

export interface SubmitButtonProps extends ButtonProps {
  loading?: boolean;
  disabled?: boolean;
  loadingPosition?: 'start' | 'end' | 'center';
  onClick?: () => void;
  children?: ReactElement | string;
}
type ButtonCustomProps = {
  isLoading?: boolean;
  label: string;
  icon?: ReactElement;
  iconLeft?: ReactElement;
  fontSizeText?: string;
  isOutline?: boolean;
  colorButton?: string;
  isIconFlower?: boolean;
} & ButtonProps &
  SubmitButtonProps;

export const PrimaryButton = ({
  label,
  isLoading,
  icon,
  iconLeft,
  fontSizeText = '16px',
  isOutline,
  isIconFlower,
  ...props
}: ButtonCustomProps) => {
  return (
    <ButtonPrimary
      outline={isOutline ? 'outline' : ''}
      {...props}
      disabled={props.disabled || isLoading}>
      {isLoading ? (
        <Loading />
      ) : (
        <Stack flexDirection={'row'} gap={1.25} alignItems={'center'}>
          {iconLeft}
          {label && (
            <TextPrimaryStyled outline={isOutline ? 'outline' : ''} sizetext={fontSizeText}>
              {label}
            </TextPrimaryStyled>
          )}
          {icon}
          {isIconFlower && <IconButtonFlower bg={'#D090D7'} />}
          {props.children}
        </Stack>
      )}
    </ButtonPrimary>
  );
};
export const BloombassadorButton = ({
  label,
  isLoading,
  icon,
  iconLeft,
  fontSizeText = '16px',
  isOutline,
  isIconFlower,
  ...props
}: ButtonCustomProps) => {
  return (
    <ButtonBloombassador outline={isOutline ? 'outline' : ''} {...props} disabled={isLoading}>
      {isLoading ? (
        <Loading />
      ) : (
        <Stack flexDirection={'row'} gap={1.25} alignItems={'center'}>
          {iconLeft}
          {label && (
            <TextBloombassador outline={isOutline ? 'outline' : ''} sizetext={fontSizeText}>
              {label}
            </TextBloombassador>
          )}
          {icon}
          {isIconFlower && <IconButtonFlower bg={'#FB6227'} />}
          {props.children}
        </Stack>
      )}
    </ButtonBloombassador>
  );
};

export const SecondaryButton = ({
  label,
  isLoading,
  icon,
  iconLeft,
  fontSizeText = '16px',
  isOutline,
  colorButton = '#770BA1',
  ...props
}: ButtonCustomProps) => {
  return (
    <ButtonSecondary
      colorbutton={colorButton}
      outline={isOutline ? 'outline' : ''}
      {...props}
      disabled={isLoading}>
      {isLoading ? (
        <Loading />
      ) : (
        <Stack flexDirection={'row'} gap={1.25} alignItems={'center'}>
          {iconLeft}
          {label && (
            <TextSecondaryStyled colorbutton={colorButton} sizetext={fontSizeText}>
              {label}
            </TextSecondaryStyled>
          )}
          {icon}
          {props.children}
        </Stack>
      )}
    </ButtonSecondary>
  );
};

export const ButtonNoBoxShadow = ({
  label,
  isLoading,
  icon,
  iconLeft,
  fontSizeText = '16px',
  ...props
}: ButtonCustomProps) => {
  return (
    <ButtonReject {...props} disabled={isLoading}>
      {isLoading ? (
        <Loading />
      ) : (
        <Stack flexDirection={'row'} gap={1.25} alignItems={'center'}>
          {iconLeft}
          {label && (
            <TextSecondaryStyled colorbutton={'#fff'} sizetext={fontSizeText}>
              {label}
            </TextSecondaryStyled>
          )}
          {icon}
          {props.children}
        </Stack>
      )}
    </ButtonReject>
  );
};

const LabelStyledBase = styled(Typography)(({ theme }: StyledParams) => ({
  fontWeight: 700,
  whiteSpace: 'pre',
  [theme.breakpoints.down(700)]: {
    width: '100%',
    height: '30px'
  }
}));

const ButtonBase = styled(Button)(() => ({
  fontSize: '20px',
  height: '50px'
}));

const ButtonSecondary = styled(ButtonBase)(
  ({ outline, colorbutton }: { outline: string; colorbutton: string }) => ({
    background: outline === 'outline' ? '#FFFFFF !important' : `#D5E359 !important`,
    boxShadow: `4px 4px 0px 0px ${colorbutton}`,
    border: `1px solid ${colorbutton}`,
    borderRadius: '20px',
    minWidth: '80px'
  })
);
const ButtonReject = styled(ButtonBase)(() => ({
  borderRadius: '20px',
  minWidth: '80px'
}));
const TextSecondaryStyled = styled(LabelStyledBase)(
  ({ sizetext, colorbutton }: { sizetext: string; colorbutton: string }) => ({
    color: colorbutton,
    fontSize: sizetext
  })
);

const ButtonPrimary = styled(ButtonBase)(({ outline }: { outline: string }) => ({
  background: outline === 'outline' ? '#FFFFFF !important' : '#D090D7 !important',
  boxShadow: outline === 'outline' ? '4px 4px 0px 0px #D090D7' : '4px 4px 0px 0px #770BA1',
  border: outline === 'outline' ? '1px solid #D090D7' : 'unset',
  borderRadius: '20px'
}));

const TextPrimaryStyled = styled(LabelStyledBase)(
  ({ sizetext, outline }: { sizetext: string; outline: string }) => ({
    color: outline === 'outline' ? '#D090D7' : '#fff',
    fontSize: sizetext
  })
);

const ButtonBloombassador = styled(ButtonBase)(({ outline }: { outline: string }) => ({
  background: outline === 'outline' ? '#FFFFFF !important' : '#FB6227 !important',
  boxShadow: outline === 'outline' ? '4px 4px 0px 0px #FB6227' : '4px 4px 0px 0px #944324',
  border: outline === 'outline' ? '1px solid #FB6227' : 'unset',
  borderRadius: '12px'
}));

const TextBloombassador = styled(LabelStyledBase)(
  ({ sizetext, outline }: { sizetext: string; outline: string }) => ({
    color: outline === 'outline' ? '#FB6227' : '#fff',
    fontSize: sizetext
  })
);

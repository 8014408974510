import { OptionsSubject } from 'app/pages/Introduction/components/FormInfo';
import { optionsSubject } from 'constants/renderListSubject';
import { Subject } from 'types/User';

export const renderColorSubject = (valueSubject?: string | number) => {
  const subject = optionsSubject.find(
    (item: OptionsSubject) => Number(item.value) === Number(valueSubject)
  );
  return subject && subject;
};

export const renderBackGroundMultiColorSubject = (subjects: Subject[]) => {
  const arrayColor = subjects.map((item) => {
    if (!item?.id) return '#fff';
    return renderColorSubject(Number(item.id))?.color;
  });
  const step = 360 / arrayColor.length;
  const stops = arrayColor.map((color, index) => `${color} ${step * index}deg`);
  return `conic-gradient(from 180deg at 50% 50%, ${stops.join(', ')})`;
};

import { Stack, styled } from '@mui/material';
import { ReactNode } from 'react';

type LayoutLandingProps = {
  children: ReactNode;
  bg?: string;
};

export const LayoutLanding = ({ children, bg }: LayoutLandingProps) => {
  return (
    <WrapperLayout>
      <Stack width={'100%'} minHeight={'100vh'} zIndex={1000}>
        {children}
      </Stack>
      <Stack width={'100%'} height={'100%'} position={'fixed'} bottom={0}>
        <img width={'100%'} height={'100%'} alt={'logo'} src={bg} />
      </Stack>
    </WrapperLayout>
  );
};

const WrapperLayout = styled(Stack)(() => ({
  alignItems: 'center',
  width: '100%'
}));

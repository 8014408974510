import { Stack, styled, Typography } from '@mui/material';

type FlowerPointProps = {
  color: string;
  point: string;
  size?: {
    width: string;
    height: string;
  };
  fontSizeTextPoint?: string;
};
export const FlowerPoint = ({ color, point, size, fontSizeTextPoint }: FlowerPointProps) => {
  return (
    <Stack width={size?.width || '163px'} height={size?.height || '157px'} position={'relative'}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size?.width || '163px'}
        height={size?.height || '157px'}
        viewBox="0 0 400 383"
        fill="none">
        <path
          d="M322.52 243.022C382.085 382.522 133.513 449.022 149.019 289.522C48.6268 402.016 -73.876 228.016 56.6124 186.016C-58.3724 114.002 74.1155 25.0156 137.585 114.002C142.112 -53.985 439.612 -24.9836 308.085 127.022C414.085 112.222 439.585 222.507 322.52 243.022Z"
          fill={color || '#D090D7'}
        />
        <path
          d="M290.879 160.664C302.285 200.595 264.051 246.528 205.481 263.257C146.911 279.987 90.1842 261.177 78.7786 221.246C67.373 181.314 105.607 135.382 164.177 118.652C222.747 101.923 279.474 120.732 290.879 160.664Z"
          fill="#FFBB0B"
        />
      </svg>
      <TextStyled fontSize={fontSizeTextPoint || '36px'}>{point}</TextStyled>
    </Stack>
  );
};
const TextStyled = styled(Typography)(() => ({
  fontWeight: 700,
  color: '#fff',
  fontFamily: 'Helvetica',
  position: 'absolute',
  top: '50%',
  left: ' 46%',
  transform: 'translate(-50%, -50%)'
}));

import { useState, useCallback, useEffect } from 'react';

/**
 *
 * @param text
 */
export const useTruncated = (
  text?: string
): [(node: HTMLDivElement) => void, boolean | undefined] => {
  const [truncated, setTruncated] = useState(false);

  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0
  );

  useEffect(() => {
    /**
     * handleResize window
     */
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth]);

  const ref = useCallback(
    (node: HTMLDivElement) => {
      if (!node) return;
      if (node.scrollHeight > node.clientHeight) setTruncated(true);
      else {
        setTruncated(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [text, windowWidth]
  );
  return [ref, truncated];
};

export const IconRecord = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12.75V11.25C0 6.3195 0 3.8535 1.362 2.193C1.61114 1.88947 1.88947 1.61114 2.193 1.362C3.855 0 6.318 0 11.25 0C16.1805 0 18.6465 0 20.307 1.362C20.6105 1.61114 20.8889 1.88947 21.138 2.193C22.191 3.4755 22.4295 5.2395 22.4835 8.25L23.4885 7.755C26.406 6.297 27.8655 5.5665 28.9335 6.2265C30 6.8865 30 8.5185 30 11.781V12.219C30 15.4815 30 17.1135 28.9335 17.7735C27.8655 18.4335 26.406 17.703 23.4885 16.2435L22.4835 15.75C22.4295 18.7605 22.191 20.5245 21.138 21.807C20.8889 22.1105 20.6105 22.3889 20.307 22.638C18.645 24 16.182 24 11.25 24C6.3195 24 3.8535 24 2.193 22.638C1.88947 22.3889 1.61114 22.1105 1.362 21.807C0 20.145 0 17.682 0 12.75ZM17.34 8.34C17.5584 8.13329 17.7332 7.88488 17.854 7.60947C17.9748 7.33405 18.0392 7.03722 18.0433 6.7365C18.0474 6.43579 17.9912 6.13731 17.8779 5.85869C17.7647 5.58008 17.5968 5.32699 17.3841 5.11438C17.1714 4.90178 16.9182 4.73397 16.6396 4.62088C16.3609 4.5078 16.0624 4.45172 15.7617 4.45597C15.461 4.46023 15.1641 4.52472 14.8888 4.64564C14.6134 4.76657 14.3651 4.94146 14.1585 5.16C13.7488 5.58455 13.5223 6.15301 13.5277 6.74295C13.5331 7.3329 13.77 7.89712 14.1874 8.31409C14.6048 8.73106 15.1692 8.96743 15.7591 8.97227C16.3491 8.97712 16.9158 8.75006 17.34 8.34Z"
        fill="white"
      />
    </svg>
  );
};

import { FC, ReactElement } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { styled, useTheme } from '@mui/material/styles';
import { TypographyProps } from '@mui/system';
import Typography from '@mui/material/Typography';
import { useTruncated } from 'hooks/useTruncated';
import { OwnerState, StyledParams } from 'types/common';

type TruncateTableTextProps = {
  text: string;
  lineNumber?: number;
  isPadding?: boolean;
  textProps?: TypographyProps;
  colorText?: string;
};

/**
 * TruncateTableText component
 * */
export const TruncateTableText: FC<TruncateTableTextProps> = ({
  text,
  lineNumber = 1,
  isPadding = true,
  colorText,
  textProps
}): ReactElement => {
  const theme = useTheme();
  const [ref, truncated] = useTruncated(text);

  return (
    <>
      {text && (
        <Box>
          {truncated ? (
            <Tooltip
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    whiteSpace: 'pre-line',
                    maxWidth: 400,
                    lineHeight: 1.5,
                    backgroundColor: theme.colors.background.lightness1,
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.25)',
                    borderRadius: '10px',
                    color: colorText || '#000',
                    fontSize: 20,
                    '.MuiTooltip-arrow': {
                      color: theme.colors.background.lightness1
                    }
                  }
                }
              }}
              title={text}>
              <TextWrapper color={colorText} ref={ref} ownerState={{ isPadding, lineNumber }}>
                <Typography {...textProps}>{text}</Typography>
              </TextWrapper>
            </Tooltip>
          ) : (
            <TextWrapper ref={ref} ownerState={{ isPadding, lineNumber }}>
              <Typography color={colorText} {...textProps}>
                {text}
              </Typography>
            </TextWrapper>
          )}
        </Box>
      )}
    </>
  );
};

const TextWrapper = styled(Box)<OwnerState>(({ theme, ownerState }: StyledParams) => ({
  overflow: 'hidden',
  WebkitLineClamp: ownerState?.lineNumber as number,
  textOverflow: 'ellipsis',
  textAlign: 'left',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  wordBreak: 'break-all',
  padding: ownerState?.isPadding ? theme.spacing(0, 2) : 0,

  [theme.breakpoints.down('sm')]: {
    fontSize: 12
  }
}));

import { Stack, styled, Typography, useMediaQuery } from '@mui/material';
import { SelectOptions } from 'app/components/elements/InputSelect';
import { InputSelectInfiniteScroll } from 'app/components/elements/InputSelectInfiniteScroll';
import { ROLES } from 'app/pages/Introduction';
import { useFormContext } from 'react-hook-form';
import { FormRecord } from 'app/pages/RecordPage';
import { InputCustom } from 'app/components/elements/InputCustom';
import { TextError } from 'app/components/elements/TextError';
import { SecondaryButton } from 'app/components/elements/ButtonCustom';
import { useCallback, useState } from 'react';
import { renderColorSubject } from 'utils/renderColorSubject';
import { StyledParams } from 'types/common';

type RecordInfoProps = {
  videoUrl?: string;
  step?: number;
  listUser?: SelectOptions[];
  userRole?: string;
  isCheckError?: boolean;
  isTutorial?: boolean;
};
export const RecordInfo = ({
  videoUrl,
  step,
  listUser,
  userRole,
  isCheckError,
  isTutorial
}: RecordInfoProps) => {
  const isMobile = useMediaQuery('(max-width:900px)');
  const [isQuestions, setIsQuestions] = useState<boolean>(true);
  const [chooseUser, setChooseUser] = useState<SelectOptions>({
    label: '',
    value: ''
  });
  const {
    register,
    setValue,
    getValues,
    formState: { errors }
  } = useFormContext<FormRecord>();

  const handleChangeUser = useCallback(
    (option: SelectOptions) => {
      setChooseUser(option);
      setValue('user', option);
    },
    [setValue]
  );
  return (
    <Stack maxHeight={'100%'}>
      <TitleStyled>Details</TitleStyled>
      <WrapperRecordVideo
        width={'100%'}
        height={'481px'}
        border={
          isMobile
            ? `10px solid ${renderColorSubject(getValues('subject'))?.color}`
            : `20px solid ${renderColorSubject(getValues('subject'))?.color}`
        }>
        <video width={'100%'} height={'100%'} src={videoUrl} />
      </WrapperRecordVideo>
      <Stack gap={1.25} mt={5}>
        <Stack flexDirection={isMobile ? 'column' : 'row'} gap={2.5}>
          <Stack gap={1}>
            <InputCustom
              inputProps={{
                ...register('title', {
                  required: {
                    value: step === 3,
                    message: 'Required Field'
                  }
                }),
                placeholder: 'What is the process of photosynthesis?',
                style: { width: isMobile ? '100%' : '480px' }
              }}
              colorlabel={'#697D21'}
              label={'Title'}
            />
            <TextError text={errors?.title?.message || ''} />
          </Stack>
          {!isTutorial && (
            <Stack gap={'10px'} mb={isMobile ? '30px' : 'unset'}>
              <Typography fontWeight={700} color={'#697D21'}>
                Does your video have a question in it?
              </Typography>
              <Stack flexDirection={'row'} gap={2.25}>
                <SecondaryButton
                  colorButton={'#697D21'}
                  isOutline={!isQuestions}
                  label={'YES'}
                  onClick={() => {
                    setIsQuestions(true);
                    setValue('isQuestions', true);
                  }}
                />
                <SecondaryButton
                  colorButton={'#697D21'}
                  isOutline={isQuestions}
                  label={'NO'}
                  onClick={() => {
                    setIsQuestions(false);
                    setValue('isQuestions', false);
                  }}
                />
              </Stack>
            </Stack>
          )}
        </Stack>
        {!isTutorial && isQuestions && (
          <Stack gap={1}>
            <InputCustom
              inputProps={{
                ...register('questions', {
                  required: {
                    value: step === 3 && isQuestions,
                    message: 'Required Field'
                  }
                }),
                placeholder: 'What is the process of photosynthesis?',
                style: { width: isMobile ? '100%' : '480px' }
              }}
              colorlabel={'#697D21'}
              label={'What is your question?'}
            />
            <TextError text={errors?.questions?.message || ''} />
          </Stack>
        )}
        {!isTutorial && userRole === ROLES.ADMIN && (
          <Stack width={isMobile ? '100%' : '480px'} gap={{ xs: 1.25, md: 2.125 }}>
            <TextStyled fontSize={{ xs: '16px', md: '20px' }} color={'#697D21'}>
              Choose User
            </TextStyled>
            <InputSelectInfiniteScroll
              label={'Choose student'}
              onChange={handleChangeUser}
              options={listUser || []}
              value={getValues('user') || chooseUser}
            />
          </Stack>
        )}
        {!isTutorial && !chooseUser.value && isCheckError && <TextError text={'Required Field'} />}
      </Stack>
    </Stack>
  );
};

const TitleStyled = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 700,
  lightHeight: '32px',
  color: '#697D21',
  marginBottom: '17px'
}));
const WrapperRecordVideo = styled(Stack)(({ theme }: StyledParams) => ({
  height: 418,
  borderRadius: '20px',
  background: '#000',
  [theme.breakpoints.up('xl')]: {
    height: '600px'
  }
}));
const TextStyled = styled(Typography)(() => ({
  fontWeight: 700,
  lineHeight: 'normal'
}));

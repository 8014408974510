import { Stack, Typography } from '@mui/material';
import { LabTabs } from 'app/components/elements/LabTabs';
import { InfoUser } from 'app/pages/DashboardPage/components/InfoUser';
import { RecentPostsTab } from 'app/pages/DashboardPage/components/RecentPostsTab';
import { ResponsesTab } from 'app/pages/DashboardPage/components/ResponsesTab';
import { UploadVideoTab } from 'app/pages/DashboardPage/components/UploadVideoTab';
import { ROLES } from 'app/pages/Introduction';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { QuestionsTab } from 'app/pages/DashboardPage/components/QuestionsTab';
import { useAuth } from 'contexts/user';

export const DashboardPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuth();
  const defaultTab = user?.role === ROLES.STUDENT ? 'recent-posts' : 'questions';
  const tabs = useMemo(() => {
    if (user?.role === ROLES.STUDENT)
      return [
        {
          id: 'recent-posts',
          label: 'Recent Posts',
          children: <RecentPostsTab />
        },
        {
          id: 'video-upload',
          label: 'Uploaded Videos',
          children: <UploadVideoTab userId={user?.id} />
        }
      ];

    return [
      {
        id: 'questions',
        label: 'Unanswered Questions',
        children: <QuestionsTab majorId={user?.major?.id} />
      },
      {
        id: 'responses',
        label: 'My Responses',
        children: <ResponsesTab userId={user?.id} />
      }
    ];
  }, [user]);

  const handleChangeTab = useCallback((value: string) => {
    setSearchParams({ tab: value });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack
      py={{ xs: 1.25, md: 5.75 }}
      px={{ xs: 1.25, md: 6.25 }}
      width={'100%'}
      overflow={'auto'}
      gap={2}>
      <Typography color={'#770BA1'} fontSize={'24px'} fontWeight={700} lineHeight={'normal'}>
        Dashboard
      </Typography>
      {user && <InfoUser user={user} />}
      <Stack>
        <LabTabs
          tabs={tabs}
          onChangeTab={handleChangeTab}
          selectedTab={searchParams.get('tab') || defaultTab}
        />
      </Stack>
    </Stack>
  );
};

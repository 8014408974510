import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FormControl, styled, Typography } from '@mui/material';
import { SelectOptions } from 'app/components/elements/InputSelect';

type InputSelectInfiniteScrollProps = {
  options: SelectOptions[];
  onChange: (value: SelectOptions) => void;
  label?: string;
  value: SelectOptions;
};
export const InputSelectInfiniteScroll = ({
  options,
  onChange,
  label,
  value: valueProp
}: InputSelectInfiniteScrollProps) => {
  return (
    <StyledFormControl>
      <Autocomplete
        id="country-select-demo"
        value={valueProp || []}
        options={options}
        isOptionEqualToValue={(_, value) => value === valueProp}
        autoHighlight
        onChange={(_, newValue: SelectOptions | null) => {
          if (!newValue) return;
          onChange(newValue);
        }}
        getOptionLabel={(option) => option.label || ''}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            <Typography color={'#000'}>{option.label}</Typography>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            inputProps={{
              ...params.inputProps,
              style: { color: '#686868' },
              autoComplete: 'new-password' // disable autocomplete and autofill
            }}
          />
        )}
      />
    </StyledFormControl>
  );
};
const StyledFormControl = styled(FormControl)(() => ({
  '& .MuiAutocomplete-popper': {
    zIndex: 'unset'
  },
  '.MuiInputLabel-outlined.Mui-focused': {
    display: 'none'
  },
  '.MuiInputLabel-outlined.MuiFormLabel-filled': {
    display: 'none'
  },
  '.MuiInputLabel-outlined': {
    fontSize: '16px',
    marginBottom: '8px',
    opacity: '0.6',
    top: '-5px'
  },
  '& fieldset': {
    borderColor: '#B4E465 !important',
    borderWidth: '1px',
    top: 0,
    '& legend': {
      display: 'none'
    }
  },
  '& .MuiOutlinedInput-root': {
    background: '#F8FDE9',
    borderRadius: '10px',
    boxShadow: '4px 4px 0 0 #B4E465',
    height: '45px',
    paddingTop: '5px !important'
  },
  '& .MuiAutocomplete-root': {
    width: '100%'
  }
}));

import { Stack, Typography } from '@mui/material';
import { IconFlowerCustom } from 'app/components/elements/icons/IconFlowerCutom';
import { renderColorSubject } from 'utils/renderColorSubject';

export const SubjectAndFlowerText = ({ subjectId }: { subjectId?: string }) => {
  return (
    <Stack key={subjectId} flexDirection={'row'} alignItems={'center'} gap={1}>
      <IconFlowerCustom subjectId={subjectId} />
      <Typography
        fontSize={'18px'}
        fontWeight={400}
        color={renderColorSubject(Number(subjectId))?.color || '#0000'}>
        {renderColorSubject(Number(subjectId))?.label}
      </Typography>
    </Stack>
  );
};

import { IconFlowerCustom } from 'app/components/elements/icons/IconFlowerCutom';
import { IconFlowerCustomMap } from 'app/components/elements/icons/IconFlowerMap';
import { IconPinMaker } from 'app/components/elements/icons/IconPinMaker';
import axios from 'axios';
import GoogleMapReact from 'google-map-react';
import { IconButton, Stack } from '@mui/material';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CityMapType } from 'types/map';
import { Answer, Questions } from 'types/questions';
import useSupercluster from 'use-supercluster';
import { ExplodingAnimationFlower } from 'utils/animation';

const MakerDetail = ({
  child,
  onClick,
  isFlower
}: {
  child: ReactElement;
  lat: number;
  lng: number;
  onClick: () => void;
  isFlower?: boolean;
}) => (
  <Stack onClick={onClick}>
    {isFlower ? (
      <IconButton style={{ padding: 0, width: '24px' }}>{child}</IconButton>
    ) : (
      <IconButton style={{ padding: 0, width: '24px', position: 'relative' }}>
        <IconPinMaker />
        <Stack position={'absolute'} top={'4px'}>
          {child}
        </Stack>
      </IconButton>
    )}
  </Stack>
);

const Marker = ({
  onClick
}: {
  lat: number;
  lng: number;
  isFlower?: boolean;
  onClick: () => void;
}) => (
  <Stack>
    <IconButton style={{ padding: 0 }} onClick={onClick}>
      <img width={'60px'} height={'60px'} src="/images/image-map.png" alt="img" />
    </IconButton>
  </Stack>
);
interface Feature {
  type: string;
  properties: {
    cluster: boolean;
    id: string;
    subjectId?: string;
    isFlower?: boolean;
  };
  geometry: {
    type: string;
    coordinates: number[];
  };
}

const KEY_MAP = process.env.REACT_APP_KEY_MAP || '';
const KEY_MAP_ID = process.env.REACT_APP_KEY_MAP_ID || '';
export const ExplorePage = () => {
  const [bounds, setBounds] = useState<number[] | null>(null);
  const [zoom, setZoom] = useState<number>(2);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mapRef = useRef<any>(null);
  const navigate = useNavigate();
  const [success, setSuccess] = useState<boolean>(false);
  const [listFlowerMarkers, setListFlowerMarkers] = useState<Feature[]>([]);
  const [listCityMarkers, setListCityMarkers] = useState<CityMapType[]>([]);
  const defaultProps = {
    center: {
      lat: 30.190528,
      lng: -41.845966
    },
    zoom: 0
  };
  const { clusters, supercluster } = useSupercluster({
    points: listFlowerMarkers,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 15 }
  });
  const fetchMarker = useCallback(async () => {
    const [resCity, resQuestionsAndAnswer] = await Promise.all([
      axios.get('/explore/city'),
      axios.get('/explore')
    ]);
    const listCity = resCity.data.cities as CityMapType[];
    const listQuestions = resQuestionsAndAnswer.data.questions as Questions[];
    const listAnswer = resQuestionsAndAnswer.data.answers as Answer[];
    const listQuestionConvert: CityMapType[] = listQuestions.map((item) => ({
      latitude: item.latitude,
      longitude: item.longitude,
      id: item.ownerId,
      subjectId: item.subject.id,
      isFlower: true
    }));
    const listAnswerConvert: CityMapType[] = listAnswer.map((item) => ({
      latitude: item.latitude,
      longitude: item.longitude,
      id: item.ownerId,
      subjectId: item.subject.id,
      isFlower: true
    }));
    const totalArray = [...listAnswerConvert, ...listQuestionConvert];
    if (
      totalArray.length === listFlowerMarkers.length &&
      listCity.length === listCityMarkers.length
    ) {
      return;
    }
    const convertArray = totalArray.map((item) => ({
      type: 'Feature',
      properties: {
        cluster: false,
        id: item.id,
        subjectId: item.subjectId,
        isFlower: true
      },
      geometry: {
        type: 'Point',
        coordinates: [parseFloat(item.longitude), parseFloat(item.latitude)]
      }
    }));
    if (listCityMarkers.length !== 0 || listFlowerMarkers.length !== 0) {
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 2500);
    }
    setListCityMarkers(listCity);
    setListFlowerMarkers(convertArray);
  }, [listCityMarkers, listFlowerMarkers]);
  const { asyncCallback: handleFetchData } = useAsyncCallback(fetchMarker, []);

  useEffect(() => {
    handleFetchData();
    const intervalId = setInterval(() => {
      handleFetchData();
    }, 30 * 1000);

    return () => clearInterval(intervalId);
  }, [handleFetchData]);

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: KEY_MAP }}
        options={{ mapId: KEY_MAP_ID }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}>
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, id, subjectId, isFlower } = cluster.properties;
          if (isCluster) {
            return (
              <Marker
                onClick={() => {
                  const expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom(cluster.id),
                    20
                  );
                  if (!mapRef.current) return;
                  mapRef.current.setZoom(expansionZoom);
                  mapRef.current.panTo({ lat: latitude, lng: longitude });
                }}
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
              />
            );
          }

          return (
            <MakerDetail
              onClick={() => navigate(`/profile/${id}`)}
              child={
                isFlower ? <IconFlowerCustom subjectId={subjectId} /> : <IconFlowerCustomMap />
              }
              lat={latitude}
              lng={longitude}
              isFlower={isFlower}
            />
          );
        })}
        {listCityMarkers.map((item) => {
          return (
            <MakerDetail
              key={item.id}
              onClick={() => navigate(`/explore/${item.id}`)}
              child={<IconFlowerCustomMap />}
              lat={Number(item.latitude)}
              lng={Number(item.longitude)}
            />
          );
        })}
      </GoogleMapReact>
      {success && (
        <Stack zIndex={9999}>
          <ExplodingAnimationFlower />
        </Stack>
      )}
    </div>
  );
};

import { Stack, Typography } from '@mui/material';
import { PrimaryButton, SecondaryButton } from 'app/components/elements/ButtonCustom';
import { DialogConfirm } from 'app/components/elements/DialogConfirm';
import { InputFile } from 'app/components/elements/InputFile';
import { useCallback, useState } from 'react';
import * as React from 'react';
import { InfoQuestions } from 'services/storage';

type ListVideoUploadProps = {
  handleUploadVideo: (item: InfoQuestions & { file?: File }) => void;
  deleteVideoUpload: (item: InfoQuestions & { file?: File }) => void;
  item: InfoQuestions;
  loading: boolean;
};

/**
 *
 * @param handleUploadVideo
 * @param errorFileUploadNotInternet
 * @constructor
 */
export const ListVideoUpload = ({
  handleUploadVideo,
  item,
  loading,
  deleteVideoUpload
}: ListVideoUploadProps) => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const handleChangeFile = useCallback((file: File) => {
    setSelectedFile(file);
  }, []);

  return (
    <Stack p={2} style={{ gap: 4, border: '3px dashed #D090D7' }} key={item.userId}>
      <Typography style={{ fontSize: '16px' }}>{`Title: ${item?.title}`}</Typography>
      <Typography
        style={{
          fontSize: '16px'
        }}>{`Name file: ${item?.fileName}`}</Typography>
      <Typography
        style={{
          fontSize: '14px',
          fontStyle: 'italic'
        }}>{`(Please select the file you downloaded previously with the name file name above)`}</Typography>
      <Stack width={'100%'} gap={1.25}>
        <InputFile
          acceptType={'video/mp4,video/x-m4v,video/*'}
          handleChangeFile={handleChangeFile}
          selectedFile={selectedFile}
          placeholder={'Add Video Record'}
        />
        <Stack
          style={{
            width: '100%',
            alignItems: 'flex-end',
            flexDirection: 'row',
            justifyContent: 'flex-end'
          }}
          gap={1.25}>
          <SecondaryButton
            isOutline
            label={'Delete'}
            onClick={() => {
              deleteVideoUpload({ ...item, file: selectedFile });
            }}
            sx={{ width: 120, borderRadius: '12px' }}
          />
          <PrimaryButton
            isLoading={loading}
            isOutline
            label={'Upload'}
            disabled={!selectedFile?.type}
            onClick={() => {
              if (selectedFile?.name !== item.fileName) {
                setIsOpenModal(true);
                return;
              }
              handleUploadVideo({ ...item, file: selectedFile });
              setSelectedFile(undefined);
            }}
            sx={{ width: 120, borderRadius: '12px' }}
          />
        </Stack>
        <DialogConfirm
          isOpen={isOpenModal}
          textButton={'Upload'}
          textButtonClose={'Cancel'}
          textTitle={`File name do not match`}
          textContent={
            'The current file name is different from the file name when you saved the video. Are you sure you want to upload this file?'
          }
          onSubmit={() => {
            handleUploadVideo({ ...item, file: selectedFile });
            setIsOpenModal(false);
            setSelectedFile(undefined);
          }}
          onClose={() => {
            setIsOpenModal(false);
          }}
        />
      </Stack>
    </Stack>
  );
};

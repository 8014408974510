import { Stack } from '@mui/material';

type IconMessageProps = {
  width?: string;
  height?: string;
};
export const IconMessage = ({ width, height }: IconMessageProps) => {
  return (
    <Stack width={`${width}px` || '45px'} height={`${height}px` || '41px'}>
      <svg
        width={width || '45'}
        height={height || '41'}
        viewBox="0 0 45 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M22.5013 0.481934C34.877 0.481934 45.0026 8.53739 45.0026 18.483C45.0026 28.4285 34.877 36.484 22.5013 36.484C19.7111 36.484 17.0335 36.079 14.5583 35.3589C7.98796 40.9843 0 40.9843 0 40.9843C5.2428 35.7415 6.07535 32.2087 6.18785 30.8587C2.36263 27.641 0 23.2757 0 18.483C0 8.53739 10.1256 0.481934 22.5013 0.481934Z"
          fill="white"
        />
      </svg>
    </Stack>
  );
};

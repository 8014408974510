import { Box, Button, Stack, styled, Typography } from '@mui/material';
import { ReactElement, useCallback } from 'react';
export type TabType = {
  id: string;
  label: string;
  children: ReactElement;
  disabled?: boolean;
};

export type LabTabsProps = {
  tabs: TabType[];
  onChangeTab?: (value: string) => void;
  width?: string;
  selectedTab?: string;
};

export const LabTabs = ({ tabs, selectedTab, onChangeTab }: LabTabsProps) => {
  const mapHeaders = tabs.map((item: TabType) => ({
    id: item.id,
    label: item.label
  }));
  const mapChildren = tabs.find((item: TabType) => item.id === selectedTab);
  const handleChange = useCallback(
    (newValue: string) => {
      onChangeTab && onChangeTab(newValue);
    },
    [onChangeTab]
  );
  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Stack flexDirection={'row'} gap={1.625} mb={2}>
        {mapHeaders.map((header) => (
          <ButtonStyled
            sx={{ minWidth: { xs: '165px', md: '187px' } }}
            key={header.id}
            active={header.id === selectedTab ? 'active' : ''}
            onClick={() => handleChange(header.id)}>
            <TextButtonStyled active={header.id === selectedTab ? 'active' : ''}>
              {header.label}
            </TextButtonStyled>
          </ButtonStyled>
        ))}
      </Stack>
      <Stack>
        <Stack width={'100%'}>{mapChildren?.children}</Stack>
      </Stack>
    </Box>
  );
};

const ButtonStyled = styled(Button)(({ active }: { active?: string }) => ({
  background: active === 'active' ? '#D090D7 !important' : '#fff !important',
  border: '1px solid #D090D7',
  borderRadius: '20px'
}));
const TextButtonStyled = styled(Typography)(({ active }: { active?: string }) => ({
  color: !active ? '#D090D7' : '#fff',
  fontSize: '14px'
}));

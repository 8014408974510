export const saveVideoFile = async (blob: Blob, fileName: string) => {
  const link = document.createElement('a');

  const objectUrl = URL.createObjectURL(blob);

  link.href = objectUrl;
  link.download = fileName;

  link.click();

  URL.revokeObjectURL(objectUrl);
  link.remove();
};

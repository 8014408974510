type ImageCircleUserProps = {
  url?: string;
};
export const ImageCircleUser = ({ url }: ImageCircleUserProps) => {
  return (
    <img
      width={'100%'}
      height={'100%'}
      style={{ borderRadius: '50%', objectFit: 'cover' }}
      alt={'avatar'}
      src={url || '/images/image-user-df.jpg'}
    />
  );
};

import { renderColorSubject } from 'utils/renderColorSubject';

type IconArrowProps = {
  subjectId: string;
};
export const IconArrow = ({ subjectId }: IconArrowProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="184"
    height="224"
    viewBox="0 0 184 224"
    fill="none">
    <g clipPath="url(#clip0_678_265)">
      <path
        d="M73.6077 6.12116C70.6845 8.25949 67.4497 9.84677 64.9185 12.2483C61.3059 15.7464 57.9012 19.6119 55.2803 24.0037C38.9224 50.9553 32.255 80.4265 39.7454 110.86C47.5476 141.845 67.7789 163.86 98.8811 174.149C114.484 179.386 130.659 180.905 147.083 179.858C150.101 179.606 152.934 179.458 157.945 179.291C153.954 184.7 150.942 188.828 147.745 193.061C148.24 193.508 148.552 194.059 149.152 194.69C150.545 194.144 152.146 193.966 153.331 193.053C159.466 188.856 165.496 184.475 171.527 180.094C175.451 177.147 179.663 174.28 178.907 168.646C178.232 162.724 173.184 160.246 168.286 158.894C161.477 157.17 154.322 156.125 147.272 155.264C145.751 155.154 144.141 157.035 142.483 157.973C143.418 159.626 144.008 161.959 145.369 162.644C149.633 164.596 154.138 165.685 158.851 167.14C158.403 167.637 158.242 168.212 157.954 168.133C109.332 176.724 68.5817 160.981 49.129 116.705C48.1703 114.581 47.3958 112.352 46.9896 109.915C40.1177 76.7064 45.8737 46.0536 65.594 18.17C68.1582 14.5375 71.7944 11.5109 75.0386 8.22118C74.6231 7.48645 74.0233 6.8559 73.6077 6.12116Z"
        fill={renderColorSubject(subjectId)?.color}
      />
    </g>
    <defs>
      <clipPath id="clip0_678_265">
        <rect
          width="202"
          height="97"
          fill="white"
          transform="matrix(0.492331 0.870408 0.870408 -0.492331 0 47.7561)"
        />
      </clipPath>
    </defs>
  </svg>
);

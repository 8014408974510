import { Stack } from '@mui/material';

type IconPenProps = {
  width?: string;
  height?: string;
};
export const IconPen = ({ width, height }: IconPenProps) => {
  return (
    <Stack width={`${width}px` || '39px'} height={`${height}px` || '39px'}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || '39'}
        height={width || '39'}
        viewBox="0 0 39 39"
        fill="none">
        <path
          d="M37.7306 9.92899L29.185 1.38187C28.9008 1.09764 28.5634 0.872177 28.1921 0.718351C27.8208 0.564525 27.4228 0.485352 27.0208 0.485352C26.6189 0.485352 26.2209 0.564525 25.8496 0.718351C25.4783 0.872177 25.1409 1.09764 24.8567 1.38187L1.27204 24.9673C0.986636 25.2504 0.76036 25.5874 0.606366 25.9588C0.452371 26.3301 0.373731 26.7283 0.375015 27.1303V35.6775C0.375015 36.489 0.69743 37.2674 1.27133 37.8413C1.84523 38.4151 2.62361 38.7375 3.43523 38.7375H11.9828C12.3848 38.7388 12.7831 38.6602 13.1544 38.5062C13.5258 38.3522 13.8628 38.1259 14.146 37.8405L37.7306 14.2571C38.0149 13.9729 38.2403 13.6355 38.3942 13.2642C38.548 12.8929 38.6272 12.4949 38.6272 12.093C38.6272 11.6911 38.548 11.2931 38.3942 10.9218C38.2403 10.5505 38.0149 10.2132 37.7306 9.92899ZM4.06831 26.4973L20.2664 10.3L23.4586 13.492L7.26049 29.6874L4.06831 26.4973ZM3.43523 30.1904L8.92257 35.6775H3.43523V30.1904ZM12.6159 35.0444L9.42368 31.8524L25.6218 15.6551L28.8139 18.8471L12.6159 35.0444ZM30.9771 16.6841L22.4296 8.13695L27.0199 3.54686L35.5674 12.0921L30.9771 16.6841Z"
          fill="white"
        />
      </svg>
    </Stack>
  );
};

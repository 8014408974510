import {
  Box,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { CustomPagination } from 'app/components/elements/Pagination';
import { TextSubjectId } from 'app/components/elements/TextSubjectId';
import { Header } from 'app/pages/AdminAnswerPage/components/AdminAnswerTable';
import dayjs from 'dayjs';
import { StyledParams } from 'types/common';
import { PagingInfo } from 'types/paging';
import { User } from 'types/User';

type AdminUserTableProps = {
  handleChangePage: (value: number) => void;
  pagination: PagingInfo;
  listUser: User[];
  headers: Header[];
};
export const AdminUserTable = ({
  handleChangePage,
  listUser,
  pagination,
  headers
}: AdminUserTableProps) => {
  return (
    <Box overflow={'auto'} width={'100%'} p={{ xs: '20px', md: '100px 50px' }}>
      <TableWrapper overflow={'auto'}>
        <TableListAdminCompany sx={{ overflow: 'auto' }}>
          <TableHead>
            <TableRow sx={{ height: 60 }}>
              {headers.map((header, index: number) => (
                <TableCellHeadStyled key={index} sx={{ width: header.width || 'unset' }}>
                  {header.label}
                </TableCellHeadStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {listUser &&
              listUser.map((user, index: number) => {
                return (
                  <TableRowStyled key={index}>
                    <TableCellStyled>
                      <TextWrapper>{user.name}</TextWrapper>
                    </TableCellStyled>
                    <TableCellStyled>
                      <TextWrapper>{user.email}</TextWrapper>
                    </TableCellStyled>
                    <TableCellStyled>
                      <TextWrapper>{user.phone}</TextWrapper>
                    </TableCellStyled>
                    <TableCellStyled>
                      <TextWrapper>{dayjs(user.birthday).format('YYYY MMM DD')}</TextWrapper>
                    </TableCellStyled>
                    <TableCellStyled>
                      <Stack flexDirection={'row'} flexWrap={'wrap'} padding={1} gap={0.8}>
                        {user?.keywords?.map((item, index) => (
                          <Stack
                            key={index}
                            style={{
                              background: '#B4E465',
                              padding: '4px',
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderRadius: '5px'
                            }}>
                            <Typography fontSize={'16px'} style={{ color: '#000' }}>
                              {item}
                            </Typography>
                          </Stack>
                        ))}
                      </Stack>
                    </TableCellStyled>
                    <TableCellStyled>
                      <Stack
                        flexDirection={'row'}
                        alignItems={'flex-start'}
                        flexWrap={'wrap'}
                        p={'10px 16px'}>
                        {user.subjects.map((subject, index) => (
                          <Stack flexDirection={'row'} alignItems={'flex-end'}>
                            <TextSubjectId subjectId={subject?.id} />
                            {index + 1 !== user.subjects.length && (
                              <Typography color={'#000'} mr={0.5}>
                                ,
                              </Typography>
                            )}
                          </Stack>
                        ))}
                      </Stack>
                    </TableCellStyled>
                    <TableCellStyled>
                      <TextWrapper>{`${user.address.flag},${user.address.city},${user.address.country}`}</TextWrapper>
                    </TableCellStyled>
                    <TableCellStyled sx={{ padding: '8px 0' }}>
                      <Stack
                        height={'50%'}
                        alignItems={'flex-start'}
                        borderBottom={'1px solid #E3E2E6'}
                        justifyContent={'center'}
                        pb={1}>
                        <TextWrapper>{`Videos Interacted With: ${user.points.interactPoint}`}</TextWrapper>
                      </Stack>
                      <Stack height={'50%'} alignItems={'flex-start'} justifyContent={'center'}>
                        <Stack flexDirection={'row'} alignItems={'center'} pt={1}>
                          <TextWrapper>{`Earned Service Hours: ${user.points.earnedServiceHours}`}</TextWrapper>
                        </Stack>
                      </Stack>
                    </TableCellStyled>
                    <TableCellStyled>
                      <TextWrapper>{user.role}</TextWrapper>
                    </TableCellStyled>
                  </TableRowStyled>
                );
              })}
          </TableBody>
        </TableListAdminCompany>
      </TableWrapper>
      <Stack
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent={'center'}
        width={'100%'}
        alignItems={'center'}
        gap={{ xs: 2, md: 'unset' }}
        pt={2.5}>
        {pagination.pageCount !== 0 && (
          <CustomPagination
            page={pagination.currentPage}
            count={pagination.pageCount}
            onChangePage={handleChangePage}
          />
        )}
      </Stack>
    </Box>
  );
};

const TableWrapper = styled(Box)(({ theme }: StyledParams) => ({
  minHeight: 'calc(100vh - 580px)',

  [theme.breakpoints.down('lg')]: {
    maxWidth: `calc(100vw - 255px)`
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: `calc(100vw - 32px)`
  }
}));

const TableListAdminCompany = styled(Table)(({ theme }: StyledParams) => ({
  [theme.breakpoints.down('lg')]: {
    minWidth: 'max-content'
  },
  [theme.breakpoints.down('md')]: {
    minWidth: 'unset'
  }
}));

const TableCellStyled = styled(TableCell)(({ theme }: StyledParams) => ({
  border: `1px solid ${theme.colors.border.base}`,
  padding: theme.spacing(0),
  fontSize: 16,
  maxWidth: '330px',
  lineHeight: '30px',
  color: theme.colors.text.lightness1,
  height: 60
}));

const TableRowStyled = styled(TableRow)(() => ({
  height: 60,
  '&:hover': {
    background: `linear-gradient(180deg, #FAFDFF 0%, #EEF7FF 9.31%, #D4EAFD 59.58%)`,
    cursor: 'pointer'
  }
}));

const TextWrapper = styled(Typography)(({ theme }: StyledParams) => ({
  padding: theme.spacing(0, 2),
  color: '#000',
  whiteSpace: 'pre-line'
}));

const TableCellHeadStyled = styled(TableCell)(({ theme }: StyledParams) => ({
  border: `1px solid ${theme.colors.border.base}`,
  textAlign: 'center',
  fontWeight: 700,
  fontSize: 16,
  lineHeight: '17px',
  color: '#770BA1',
  padding: theme.spacing(0, 0),
  backgroundColor: '#D090D7'
}));

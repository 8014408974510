import { LinearProgress, Stack, styled, Typography, useMediaQuery } from '@mui/material';
import { ROLES } from 'app/pages/Introduction';
import axios from 'axios';
import { useAuth } from 'contexts/user';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useLoading } from 'contexts/loading';
import { StorageServices } from 'services/storage';
import { StyledParams } from 'types/common';
import { User } from 'types/User';
import { unregister } from '../../../serviceWorkerRegistration';

type SideMenu = {
  label: string;
  link: string;
  iconActive?: string;
  iconInactive?: string;
  hidden?: boolean;
  paths?: string[];
  items?: SideMenu[];
};
const storage = new StorageServices();

type LayoutProps = {
  children: ReactNode;
};
const heightWindow = window.innerHeight;
export const Layout = ({ children }: LayoutProps) => {
  const navigate = useNavigate();
  const { user, setUser } = useAuth();
  const role = storage.getRoleName();
  const { loading: callbackLoading } = useLoading();
  const isMobile = useMediaQuery('(max-width:900px)');
  const handleChoosePage = useCallback((item: SideMenu) => {
    navigate(item.link);
    // eslint-disable-next-line
  }, []);

  const fetchInfoUser = useCallback(async () => {
    const res = await axios.get('/auth/me');
    const user = res.data.user as User;
    storage.setRoleName(user.role);
    setUser(user);
    // eslint-disable-next-line
  }, []);

  const handleLogout = useCallback(() => {
    unregister();
    storage.removeAccessToken();
    storage.removeRefreshToken();
    setUser(null);
    navigate('/login');
  }, []);

  useEffect(() => {
    fetchInfoUser();
  }, []);

  const MENU: SideMenu[] = useMemo(() => {
    return [
      {
        label: 'WATCH',
        link: '/',
        iconActive: '/icons/icon_watch-active.svg',
        iconInactive: '/icons/icon_watch-inactive.svg',
        paths: ['/']
      },
      {
        label: 'SEARCH',
        link: '/search',
        iconActive: '/icons/icon_search-active.svg',
        iconInactive: '/icons/icon_search-inactive.svg',
        paths: ['/search']
      },
      {
        label: 'RECORD',
        link: '/record',
        iconActive: '/icons/icon_record-active.svg',
        iconInactive: '/icons/icon_record-inactive.svg',
        paths: ['/record'],
        hidden: role !== ROLES.STUDENT
      },
      {
        label: 'EXPLORE',
        link: '/explore',
        iconActive: '/icons/icon_explore-active.svg',
        iconInactive: '/icons/icon_explore-inactive.svg',
        paths: ['/explore', '/explore/:id']
      },
      {
        label: 'DASHBOARD',
        link: '/dashboard',
        iconActive: '/icons/icon_dashboard-active.svg',
        iconInactive: '/icons/icon_dashboard-inactive.svg',
        paths: ['/dashboard']
      }
    ];
  }, [user, role]);

  const MENU_ADMIN: SideMenu[] = useMemo(() => {
    return [
      {
        label: 'USERS',
        link: '/'
      },
      {
        label: 'RECORD',
        link: '/record'
      },
      {
        label: 'QUESTIONS',
        link: '/questions'
      },
      {
        label: 'ANSWERS',
        link: '/answers'
      },
      {
        label: 'COMMENT',
        link: '/comments'
      },
      {
        label: 'LOGOUT',
        link: '/logout'
      }
    ];
  }, []);

  const isCheckResponsiveMenu = useMemo(() => {
    if (user?.role === ROLES.BLOOMBASSADOR) {
      return heightWindow <= 830;
    }
    if (user?.role === ROLES.STUDENT) {
      return heightWindow <= 1000;
    }
    return true;
  }, [heightWindow, user?.role]);

  return (
    <Stack
      minHeight={'100vh'}
      flexDirection={isMobile ? 'column' : 'row'}
      width={'100%'}
      position={'relative'}>
      {callbackLoading && <LinearLoading color="success" />}
      {isMobile ? (
        <Wrapper
          zIndex={5}
          position={'fixed'}
          bottom={0}
          width={'100%'}
          height={'90px'}
          sx={{ background: '#D090D7', maxWidth: '100vw' }}
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          px={2}
          gap={0.75}>
          {(user?.role === ROLES.ADMIN ? MENU_ADMIN : MENU).map((item, index) => {
            if (item.hidden) return;
            return (
              <NavLink key={index} to={item.link} style={{ maxWidth: '75px', width: '100%' }}>
                <WrapperItemMenu
                  className={'button-navbar'}
                  py={1.5}
                  onClick={() => {
                    if (item.link === '/logout') {
                      handleLogout();
                    }
                    handleChoosePage(item);
                  }}>
                  {item.iconActive && (
                    <img
                      className={'icon-active'}
                      width={26}
                      height={26}
                      src={item.iconActive}
                      alt={'icon-menu'}
                      style={{ display: 'none' }}
                    />
                  )}
                  {item.iconInactive && (
                    <img
                      className={'icon-inactive'}
                      width={26}
                      height={26}
                      src={item.iconInactive}
                      alt={'icon-menu'}
                    />
                  )}
                </WrapperItemMenu>
              </NavLink>
            );
          })}
        </Wrapper>
      ) : (
        <WrapperNavbar py={2.125} px={1.5}>
          <img
            style={{ cursor: 'pointer' }}
            alt={'img'}
            width={143}
            height={143}
            src={'/images/logo-navbar.png'}
            onClick={() => navigate('/')}
          />
          <Wrapper width={'100%'} gap={1.5} mt={6.25}>
            {(user?.role === ROLES.ADMIN ? MENU_ADMIN : MENU).map((item, index) => {
              if (item.hidden) return;
              return (
                <NavLink to={item.link} key={index}>
                  <WrapperItem
                    flexDirection={isCheckResponsiveMenu ? 'row' : 'column'}
                    padding={isCheckResponsiveMenu ? '32px 12px' : '22px 12px'}
                    onClick={() => {
                      if (item.link === '/logout') {
                        handleLogout();
                      }
                    }}
                    className={'button-navbar'}
                    gap={'14px'}>
                    {item.iconActive && (
                      <img
                        className={'icon-active'}
                        width={isCheckResponsiveMenu ? 40 : 47}
                        height={isCheckResponsiveMenu ? 40 : 47}
                        style={{ display: 'none' }}
                        src={item.iconActive}
                        alt={'icon-menu'}
                      />
                    )}
                    {item.iconInactive && (
                      <img
                        className={'icon-inactive'}
                        width={isCheckResponsiveMenu ? 40 : 47}
                        height={isCheckResponsiveMenu ? 40 : 47}
                        src={item.iconInactive}
                        alt={'icon-menu'}
                      />
                    )}
                    <TextStyled
                      fontSize={isCheckResponsiveMenu ? '20px' : '24px'}
                      className={'text-menu'}>
                      {item.label}
                    </TextStyled>
                  </WrapperItem>
                </NavLink>
              );
            })}
          </Wrapper>
        </WrapperNavbar>
      )}
      <WrapperChild>{children}</WrapperChild>
    </Stack>
  );
};
const WrapperChild = styled(Stack)(({ theme }: StyledParams) => ({
  [theme.breakpoints.up(1800)]: {
    marginLeft: '12%'
  },
  [theme.breakpoints.down(900)]: {
    marginLeft: 0
  },
  marginLeft: 253,
  width: '100%',
  alignItems: 'center'
}));
const Wrapper = styled(Stack)(({ theme }: StyledParams) => ({
  '.active': {
    '.button-navbar': {
      background: `${theme.colors.text.darkness1} !important`,
      '.icon-inactive': {
        display: 'none'
      },
      '.icon-active': {
        display: 'block !important'
      },
      '.text-menu': {
        color: theme.colors.text.lightness1
      }
    }
  }
}));

const LinearLoading = styled(LinearProgress)(() => ({
  position: 'fixed',
  width: '100vw',
  zIndex: 1000
}));
const WrapperNavbar = styled(Stack)(({ theme }: StyledParams) => ({
  [theme.breakpoints.up(2500)]: {
    width: '12%'
  },
  width: 255,
  background: theme.colors.background.darkness1,
  alignItems: 'center',
  height: '100vh',
  boxShadow: '6px 0px 15px 0px rgba(63, 0, 88, 0.50)',
  zIndex: 5,
  position: 'fixed',
  overflow: 'auto'
}));

const WrapperItem = styled(Stack)(({ theme }: StyledParams) => ({
  width: '100%',
  background: theme.colors.text.lightness1,
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '20px',
  gap: '14px'
}));
const TextStyled = styled(Typography)(({ theme }: StyledParams) => ({
  fontWeight: '700',
  color: theme.colors.text.darkness1
}));
const WrapperItemMenu = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '20px',
  height: '60px',
  maxWidth: '75px !important',
  width: '100% !important',
  background: '#fff'
}));

import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { ThemeColors } from 'types/common';

const themeColors: ThemeColors = {
  text: {
    base: '#686868',
    lightness1: '#FFFFFF',
    lightness2: '#696969',
    lightness3: '#71ADE7',
    lightness4: '#B0CEF0',
    lightness5: '#BDD8F4',
    darkness1: '#770BA1',
    darkness2: '#B7B7B7',
    darkness3: '#828282',
    darkness5: '#000000'
  },
  background: {
    base: '#FFFFFF',
    lightness1: '#F8FDE9',
    lightness2: '#B4E465',
    lightness3: '#697D21',
    lightness4: '#F1EFF4',
    lightness5: '#FFE2E1',
    lightness6: '#F4527D',
    lightness7: '#D2F2F1',
    lightness8: '#FED9D9',
    lightness9: '#FFECEC',
    darkness1: '#D090D7',
    darkness2: '#E94442',
    darkness3: '#828282',
    darkness4: '#FEF6F8',
    darkness5: '#F7F7F7',
    darkness6: '#175795',
    darkness7: '#26ADA5',
    darkness8: '#D9D9D9'
  },
  border: {
    base: '#E3E2E6',
    lightness1: '#EBEBEB',
    lightness2: '#E7EDFF',
    lightness3: '#227DD7',
    lightness4: '#F7F8FD',
    lightness5: '#FF1744',
    lightness6: '#F4527D',
    lightness7: '#D2F2F1',
    lightness8: '#FED9D9',
    lightness9: '#FFECEC',
    darkness1: '#C1C1C1',
    darkness2: '#4F4F4F',
    darkness3: '#E0E0E0',
    darkness5: '#000'
  }
};

// Create a theme instance.
const theme = createTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536
      }
    },
    spacing: 8,
    palette: {
      primary: {
        main: themeColors.text.base,
        light: '#5CA0E3'
      },
      secondary: {
        main: '#19857b',
        light: themeColors.text.lightness1
      },
      error: {
        main: red.A400,
        light: themeColors.background.darkness2
      },
      text: {
        primary: themeColors.text.lightness1
      }
    }
  },
  { colors: themeColors }
);

export default theme;

import React from 'react';
import { motion } from 'framer-motion';

type AnimationLikeProps = {
  startAnimation: boolean;
};
export const AnimationLike = ({ startAnimation }: AnimationLikeProps) => {
  const animationVariants = {
    initial: {
      scale: 0,
      opacity: 0
    },
    animate: {
      scale: 1,
      opacity: 1,
      transition: { duration: 0.5 }
    },
    exit: {
      scale: 0,
      opacity: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <motion.div
      variants={animationVariants}
      initial="initial"
      animate={startAnimation ? 'animate' : 'exit'}
      style={{
        position: 'absolute',
        top: '44%',
        left: '44%',
        transform: 'translate(-50%, -50%)',
        width: '100px',
        height: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontSize: '60px',
        lineHeight: '100px',
        userSelect: 'none'
      }}>
      <svg
        width="132"
        height="133"
        viewBox="0 0 132 133"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="58.4512" cy="69.6464" rx="40.0505" ry="26.5033" fill="white" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M101.761 44.124C111.439 35.28 115.615 23.4877 111.293 13.9481C105.401 0.943564 85.9498 -2.95019 67.8465 5.25116C52.0616 12.4022 42.5138 26.3877 44.2327 38.4734C41.6814 35.525 38.3508 32.9057 34.3991 30.9288C22.0633 24.7573 8.34713 27.1823 3.76314 36.345C-0.458431 44.7833 4.53753 55.9934 14.9891 62.5336C14.8433 62.6393 14.6979 62.7462 14.553 62.8544C0.767748 73.1427 -3.71945 90.4443 4.53061 101.498C12.7807 112.553 30.6438 113.174 44.4291 102.885C46.3483 101.453 48.0873 99.8846 49.6367 98.2162C47.3931 106.352 48.5375 114.9 53.5548 121.622C62.8942 134.136 82.1167 135.585 96.4894 124.858C110.713 114.243 114.856 95.6827 105.89 83.1703C107 83.0531 108.121 82.8816 109.248 82.6535C123.582 79.7523 133.459 68.7885 131.308 58.1652C129.173 47.616 115.981 41.3695 101.761 44.124ZM63.2636 86.8968C81.9215 83.0243 95.1631 70.8096 92.8395 59.6146C90.5159 48.4196 73.5071 42.4836 54.8492 46.3561C36.1913 50.2286 22.9497 62.4432 25.2733 73.6382C27.5969 84.8332 44.6057 90.7693 63.2636 86.8968Z"
          fill="#FF4B88"
        />
      </svg>
    </motion.div>
  );
};

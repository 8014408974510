import { useState, useContext, createContext, FC, ReactNode } from 'react';
import { User } from 'types/User';

type InfoUserContext = {
  user: User | null;
  setUser: (infoUser: User | null) => void;
};

type InfoUserProps = {
  children: ReactNode;
};

const InfoUserContext = createContext<InfoUserContext>({
  user: null,
  setUser: () => null
});

/**
 * @return useContext use context infoUser
 */
export function useAuth(): InfoUserContext {
  return useContext(InfoUserContext);
}
/**
 * @return InfoUserProvider provider value infoUser
 */
export const InfoUserProvider: FC<InfoUserProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const value = { user, setUser };
  return <InfoUserContext.Provider value={value}>{children}</InfoUserContext.Provider>;
};

import { Typography } from '@mui/material';

type TextErrorProps = {
  text: string;
};
export const TextError = ({ text }: TextErrorProps) => {
  return (
    <Typography color="red" fontSize={'16px'} fontWeight="normal" height={'24px'} ml={1}>
      {text}
    </Typography>
  );
};

import { Button, Stack, Typography } from '@mui/material';
import { DialogPreviewRecord } from 'app/components/elements/DialogPreviewRecord';
import { ImageUser } from 'app/components/elements/ImageUser';
import { TextSubjectId } from 'app/components/elements/TextSubjectId';
import { TruncateTableText } from 'app/components/elements/TruncateTableText';
import { ROLES } from 'app/pages/Introduction';
import { useMemo, useState } from 'react';
import { Questions } from 'types/questions';
import { renderColorSubject } from 'utils/renderColorSubject';

type RecentPostsCardQuestionsAnswerProps = {
  infoCard: Questions;
};
export const RecentPostsCardQuestionsAnswer = ({
  infoCard
}: RecentPostsCardQuestionsAnswerProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isStudent = useMemo(() => {
    return infoCard.user.role === ROLES.STUDENT;
  }, [infoCard.user.role]);

  return (
    <Stack key={infoCard.id} flexDirection={'row'} alignItems={'center'}>
      <Stack
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems={'center'}
        width={'100%'}
        justifyContent={'space-between'}
        borderBottom={'1px solid #D7D7D7'}
        py={2.5}>
        <Stack flexDirection={'row'} gap={2} alignItems={'center'}>
          <Stack minWidth={'113px'} width={'113px'} height={'113px'}>
            <ImageUser
              borderWidth={'5px'}
              subjects={isStudent ? infoCard?.user?.subjects : [infoCard?.user?.major]}
              isCircle
              url={infoCard.user.avatar}
            />
          </Stack>
          <Stack maxWidth={180}>
            <TruncateTableText
              isPadding={false}
              textProps={{ fontSize: '24px', fontWeight: 700 }}
              text={infoCard?.user?.name}
              colorText={renderColorSubject(infoCard.user?.major?.id)?.color || '#973DAD'}
            />
            {isStudent &&
              infoCard.user.subjects.map((subject) => (
                <Stack key={subject.id} flexDirection={'row'} alignItems={'center'} gap={1}>
                  <TextSubjectId subjectId={subject?.id} />
                </Stack>
              ))}
            {!isStudent && infoCard.user?.major?.id && (
              <TextSubjectId subjectId={infoCard.user?.major?.id} />
            )}
            <TruncateTableText
              isPadding={false}
              textProps={{ fontSize: '20px', fontWeight: 400 }}
              text={infoCard.user.school}
              colorText={renderColorSubject(infoCard.user?.major?.id)?.color || '#973DAD'}
            />
          </Stack>
        </Stack>
        <Stack flexDirection={'row'} gap={2} alignItems={'center'}>
          <Stack gap={2.5}>
            <Stack flexDirection={'row'} gap={2}>
              <img src={'/images/icon_interacted-flower.png'} alt={'icon'} width={42} height={42} />
              <Stack>
                <Typography color={'#303972'} fontSize={'20px'} fontWeight={700}>
                  {infoCard.user.points.interactPoint}
                </Typography>
                <Typography fontSize={'14px'} color={'#A098AE'}>
                  Flowers Given
                </Typography>
              </Stack>
            </Stack>
            <Stack flexDirection={'row'} gap={2}>
              <img src={'/images/icon_service-flower.png'} alt={'icon'} width={42} height={42} />
              <Stack>
                <Typography color={'#303972'} fontSize={'20px'} fontWeight={700}>
                  {infoCard.user.points.earnedServiceHours}
                </Typography>
                <Typography fontSize={'14px'} color={'#A098AE'}>
                  Flowers Received
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack gap={2.5}>
            <Stack flexDirection={'row'} gap={2}>
              <img src={'/icons/icon_chart.svg'} alt={'icon-chart'} />
              <Stack>
                <Typography color={'#303972'} fontSize={'20px'} fontWeight={700}>
                  {infoCard.user.role === ROLES.STUDENT
                    ? infoCard.user?.videoQuestion
                    : infoCard.user?.videoResponse}
                </Typography>
                <Typography fontSize={'14px'} color={'#A098AE'}>
                  {infoCard.user.role === ROLES.STUDENT ? 'Video Questions' : 'Video Responses'}
                </Typography>
              </Stack>
            </Stack>
            <Button
              sx={{
                background: '#D090D7 !important',
                borderRadius: '20px',
                height: '50px'
              }}
              onClick={() => setIsOpen(true)}>
              <Stack
                flexDirection={'row'}
                gap={1.25}
                alignItems={'center'}
                sx={{ cursor: 'pointer' }}>
                <Typography fontSize={'16px'} fontWeight={700} color={'#fff'}>
                  View Video
                </Typography>
                <img alt={'button-img'} src={'icons/icon_next.svg'} />
              </Stack>
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <DialogPreviewRecord
        isLiked={infoCard.isLiked}
        questionsId={infoCard?.id}
        comments={infoCard.comments}
        isQuestion={isStudent}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        url={infoCard.record.url}
        subjectId={infoCard?.subject?.id || '1'}
      />
    </Stack>
  );
};

import { Stack } from '@mui/material';
import { InputCustom } from 'app/components/elements/InputCustom';
import { SecondaryButton } from 'app/components/elements/ButtonCustom';
import { InputSelect } from 'app/components/elements/InputSelect';
import { useLoading } from 'contexts/loading';
import { Controller, useFormContext } from 'react-hook-form';
import { TextError } from 'app/components/elements/TextError';
import { ROLES, TypeFormSignUp } from 'app/pages/Introduction';
import { InputSelectMultiple } from 'app/components/elements/InputSelectMultiple';
import { renderListSubject } from 'constants/renderListSubject';

export type OptionsSubject = {
  label: string;
  value: string;
  color: string;
};

type FormInfoProps = {
  onProceed: () => void;
  onCancel: () => void;
  isMobile: boolean;
  activeStep: number;
};
export const FormInfo = ({ onProceed, onCancel, isMobile, activeStep }: FormInfoProps) => {
  const { loading } = useLoading();
  const {
    control,
    getValues,
    formState: { errors },
    handleSubmit
  } = useFormContext<TypeFormSignUp>();
  return (
    <Stack width={'100%'} alignItems={'center'} mt={isMobile ? '133px' : 'unset'}>
      <Stack
        maxWidth={isMobile ? '100%' : 400}
        alignItems={'center'}
        gap={'17px'}
        px={isMobile ? '25px' : 'unset'}>
        {getValues('role') === ROLES.BLOOMBASSADOR && (
          <Stack width={isMobile ? '100%' : '403px'} gap={1}>
            <Controller
              rules={{
                required: {
                  value: activeStep === 6,
                  message: 'Required Field'
                }
              }}
              render={({ field }) => (
                <InputCustom
                  isCenter={!isMobile}
                  label={'What school do you go to?'}
                  inputProps={{
                    ...field,
                    placeholder: 'Stanford University',
                    style: { width: isMobile ? '100%' : '403px' }
                  }}
                />
              )}
              name="school"
              control={control}
            />
            <TextError text={errors.school?.message || ''} />
          </Stack>
        )}
        {getValues('role') === ROLES.BLOOMBASSADOR && (
          <Stack width={isMobile ? '100%' : '403px'} gap={1}>
            <Controller
              rules={{
                required: {
                  value: activeStep === 6,
                  message: 'Required Field'
                }
              }}
              render={({ field }) => (
                <InputSelect
                  isCenterLabel
                  label={'What is your major?'}
                  inputProps={{
                    ...field,
                    style: { width: isMobile ? '100%' : '480px', padding: '0 20px 0 20px' },
                    color: '#686868'
                  }}
                  placeholder={'Select major'}
                  options={renderListSubject()}
                />
              )}
              name="major"
              control={control}
            />
            <TextError text={errors.major?.message || ''} />
          </Stack>
        )}
        <Stack width={isMobile ? '100%' : '403px'} gap={1}>
          <Controller
            rules={{
              required: {
                value: activeStep === 6,
                message: 'Required Field'
              }
            }}
            render={({ field }) => (
              <InputSelectMultiple
                isCenterLabel={!isMobile}
                placeholder={'Select subject'}
                label={
                  getValues('role') === ROLES.BLOOMBASSADOR
                    ? 'What subjects are you comfortable responding to questions about?'
                    : 'What subjects are you interested in?'
                }
                options={renderListSubject()}
                inputProps={{
                  ...field,
                  defaultValue: [],
                  style: { width: isMobile ? '100%' : '403px' },
                  color: '#686868'
                }}
              />
            )}
            name="subjects"
            control={control}
          />
          <TextError text={errors.subjects?.message || ''} />
        </Stack>
        <Stack
          width={'100%'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          mt={2.375}
          gap={isMobile ? '20px' : 'unset'}>
          <SecondaryButton
            isLoading={loading}
            colorButton={'#770BA1'}
            label={'Continue'}
            onClick={handleSubmit(onProceed)}
            style={{ width: isMobile ? '100%' : 193 }}
          />
          <SecondaryButton
            colorButton={'#770BA1'}
            isOutline
            label={'Cancel'}
            onClick={onCancel}
            style={{ width: isMobile ? '100%' : 193 }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Stack,
  styled,
  Typography
} from '@mui/material';
import * as React from 'react';
import { ReactElement } from 'react';
export type SelectOptions = {
  value: number | string;
  label: string;
  icon?: ReactElement;
  longitude?: string | null;
  latitude?: string | null;
  flag?: string;
};
type InputSelectProps = SelectProps & {
  options: SelectOptions[];
  title?: string;
  value?: number | string | null;
  onChange?: (e: SelectChangeEvent<unknown>) => void;
  placeholder?: string;
  defaultValue?: number | string;
  label?: string;
  colorlabel?: string;
  isCenterLabel?: boolean;
};

export const InputSelect = ({
  options,
  onChange,
  value,
  defaultValue,
  label,
  colorlabel,
  isCenterLabel,
  ...rest
}: InputSelectProps) => {
  return (
    <FormControl fullWidth>
      <Stack width={'100%'} gap={2.125}>
        {label && (
          <TextStyled
            fontSize={{ xs: '16px', md: '20px' }}
            textAlign={isCenterLabel ? 'center' : 'unset'}
            colorlabel={colorlabel || '#770BA1'}>
            {label}
          </TextStyled>
        )}
        <SelectStyled
          fullWidth
          value={value || ''}
          defaultValue={defaultValue || ''}
          onChange={onChange}
          {...rest}>
          {options?.map((op: SelectOptions) => {
            return (
              <MenuItemStyled key={op.value} value={op.value}>
                <Stack alignItems={'center'} flexDirection={'row'} gap={1.75}>
                  {op?.icon}
                  <TextStyled fontSize={{ xs: '16px', md: '20px' }}>{op.label}</TextStyled>
                </Stack>
              </MenuItemStyled>
            );
          })}
        </SelectStyled>
      </Stack>
    </FormControl>
  );
};

const SelectStyled = styled(Select)(() => ({
  height: 48,
  '&.MuiInputBase-root': {
    background: '#F8FDE9',
    borderRadius: '20px',
    boxShadow: '4px 4px 0 0 #B4E465',
    outline: 'none',
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: '#B4E465'
    },
    '.MuiTypography-body1': {
      color: '#686868 !important'
    }
  }
}));

const MenuItemStyled = styled(MenuItem)(() => ({
  padding: '5px 16px'
}));
const TextStyled = styled(Typography)(({ colorlabel }: { colorlabel?: string }) => ({
  fontWeight: 700,
  lineHeight: 'normal',
  color: colorlabel
}));

import { Stack, Typography } from '@mui/material';
import { useFlash } from 'contexts/flash';
import { useCallback, useEffect, useState } from 'react';
import { RecordVideo } from 'app/components/elements/RecordVideo';
import { BloombassadorButton } from 'app/components/elements/ButtonCustom';
import { FormProvider, useForm } from 'react-hook-form';
import { StorageServices } from 'services/storage';
import { LocationAddress, Questions } from 'types/questions';
import { ExplodingAnimationFlower } from 'utils/animation';
import { DialogConfirm } from 'app/components/elements/DialogConfirm';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import dayjs from 'dayjs';
import { AnswerForm } from 'app/pages/DashboardPage/components/AnswerForm';
const storage = new StorageServices();

export type FormAnswer = {
  video: string;
  title: string;
  attachment: File;
  lat: number;
  lng: number;
};
export const AnswerPage = () => {
  const { id } = useParams();
  const { setFlash } = useFlash();
  const methods = useForm<FormAnswer>();
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(1);
  const [recordVideo, setRecordVideo] = useState<string>();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const [question, setQuestion] = useState<Questions>();
  const lat = methods.watch('lat');
  const lng = methods.watch('lng');

  const getQuestionsId = useCallback(async () => {
    const res = await axios.get(`/questions/${id}`);
    if (!res.data) return;
    setQuestion(res.data.question);
  }, [id]);
  const { asyncCallback: fetchData } = useAsyncCallback(getQuestionsId, []);
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!recordVideo) setStep(1);
  }, [recordVideo]);

  const getLongLat = useCallback(async () => {
    try {
      if (!navigator.geolocation) {
        setFlash({ message: 'Geolocation is not supported by your browser', type: 'error' });
        return;
      }
      const mediaDevicesPermission = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true
      });
      if (!mediaDevicesPermission) {
        setFlash({ message: 'Camera or microphone permission not grantedranted', type: 'error' });
        return;
      }
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          methods.setValue('lng', longitude);
          methods.setValue('lat', latitude);
        },
        () => {
          setFlash({ message: 'Geolocation permission not granted', type: 'error' });
        }
      );
    } catch (error) {
      setFlash({ message: 'Geolocation permission not granted', type: 'error' });
    }
  }, []);

  useEffect(() => {
    if (lat && lng) return;
    getLongLat();
  }, []);

  const handleRecordVideo = useCallback((video?: string, location?: LocationAddress) => {
    setRecordVideo(video);
    if (!location) return;
    methods.setValue('lat', location.lat);
    methods.setValue('lng', location.lng);
  }, []);

  const handleCancel = useCallback(() => {
    navigate(`/dashboard/questions/${id}`);
    setRecordVideo('');
  }, []);
  const renderStep = useCallback(() => {
    if (step === 1)
      return (
        <RecordVideo
          isAnswer
          handleCancel={handleCancel}
          subjectId={question?.subject.id}
          onSubmit={handleRecordVideo}
        />
      );
    if (step === 2)
      return <AnswerForm step={step} videoUrl={recordVideo} subjectId={question?.subject.id} />;
    return;
    // eslint-disable-next-line
    }, [step,question]);

  const postRecord = useCallback(
    async (value: FormAnswer) => {
      const formData = new FormData();
      const fileDuration = storage.getFileDuration();
      if (step === 1) return setStep((prevState) => prevState + 1);
      if (!recordVideo || !fileDuration) return;
      const res = await fetch(recordVideo);
      const blob = await res.blob();
      formData.append('record', blob, `video-${dayjs().unix()}.mp4`);
      formData.append('recordDuration', fileDuration);
      formData.append('attachment', value.attachment);

      const payload = {
        questionId: Number(id),
        title: value.title,
        longitude: `${value.lng || ''}`,
        latitude: `${value.lat || ''}`
      };
      const { data: created } = await axios.post('/answers', payload);
      formData.append('answerId', created?.answer.id);

      await axios.post('/storages/answers', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        setIsOpenModal(true);
      }, 2500);
    },
    [recordVideo, step, id]
  );

  const { asyncCallback: handlePostRecord } = useAsyncCallback(postRecord, []);

  return (
    <Stack maxWidth={'995px'} padding={{ xs: '20px', md: '20px 70px 20px 70px' }} width={'100%'}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handlePostRecord)}>
          <Typography color={'#770BA1'} fontSize={'24px'} fontWeight={700} mb={0.5}>
            {`Responding to ${question?.user.name} Question`}
          </Typography>
          {renderStep()}
          {recordVideo && (
            <Stack
              position={'relative'}
              zIndex={step === 1 ? 100 : 1}
              width={'100%'}
              justifyContent={'flex-end'}
              flexDirection={'row'}
              gap={2.5}>
              <BloombassadorButton
                isOutline
                label={'Cancel'}
                onClick={handleCancel}
                sx={{ width: 170 }}
              />
              <BloombassadorButton
                type={'submit'}
                label={'UPLOAD '}
                icon={<img src={'/icons/icon_tree-flower.svg'} />}
                sx={{ width: 170 }}
              />
            </Stack>
          )}
        </form>
      </FormProvider>

      {success && (
        <Stack zIndex={9999}>
          <ExplodingAnimationFlower />
        </Stack>
      )}
      <DialogConfirm
        isOpen={isOpenModal}
        textButton={'Back to Dashboard'}
        textTitle={'Uploaded Successfully'}
        textContent={'Keep an eye out under your saved videos for a response'}
        onSubmit={() => {
          setIsOpenModal(false);
          navigate('/dashboard');
        }}
        onClose={() => {
          setIsOpenModal(false);
          navigate('/dashboard');
        }}
      />
    </Stack>
  );
};

import { Button, Stack, styled, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { InputCustom } from 'app/components/elements/InputCustom';
import { trim } from 'lodash';
import { StyledParams } from 'types/common';
import { Controller, useFormContext } from 'react-hook-form';
import { TypeFormSignUp } from 'app/pages/Introduction';
import { TextError } from 'app/components/elements/TextError';
import { SetStateAction, useCallback, useState } from 'react';
import dayjs from 'dayjs';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

type FormBirthdayProps = {
  isMobile: boolean;
  activeStep: number;
};
export const FormBirthday = ({ isMobile, activeStep }: FormBirthdayProps) => {
  const [valueKeyWord, setValueWord] = useState<string>('');
  const {
    control,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors }
  } = useFormContext<TypeFormSignUp>();

  const keywords = watch('keywords');
  const validate = useCallback((value: string) => {
    const isInvalid = dayjs(value).isValid();
    return isInvalid || 'value is invalid';
  }, []);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter' && valueKeyWord) {
      if (keywords && keywords.includes(trim(valueKeyWord))) {
        setError('keywords', { message: 'Value already exists.' });
        return;
      }
      clearErrors('keywords');
      event.preventDefault();
      const newListKey = keywords ? [...keywords, valueKeyWord] : [valueKeyWord];
      setValue('keywords', newListKey);
      setValueWord('');
    }
  };
  const deleteKey = (key: string) => {
    const newListKey = keywords?.filter((keyOld) => keyOld !== key);
    setValue('keywords', newListKey);
  };
  return (
    <Stack width={'100%'} alignItems={'center'} mt={5} gap={1.25}>
      <Stack width={isMobile ? '100%' : '402px'} alignItems={isMobile ? 'flex-start' : 'center'}>
        <Typography color={'#770BA1'} fontSize={20} fontWeight={700}>
          When is your birthday?
        </Typography>
        <Stack width={'100%'} gap={1}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              sx={{ width: isMobile ? '100%' : '402px' }}
              components={['DatePicker', 'DatePicker']}>
              <Controller
                control={control}
                name={'birthday'}
                rules={{
                  required: {
                    value: activeStep === 2,
                    message: 'Required Field'
                  },
                  validate: validate
                }}
                render={({ field }) => (
                  <StyledDatePicker
                    {...field}
                    maxDate={dayjs('01/01/2020')}
                    sx={{ width: isMobile ? '100%' : '402px' }}
                    label="Date of Birth"
                  />
                )}
              />
            </DemoContainer>
          </LocalizationProvider>
          {errors?.birthday?.message && <TextError text={errors?.birthday?.message} />}
        </Stack>
        <Stack width={'100%'} gap={2.5} mt={2.5}>
          <InputCustom
            isCenter={!isMobile}
            label={'What are you interested in?'}
            inputProps={{
              disabled: keywords?.length >= 5,
              value: valueKeyWord,
              onChange: (e: { target: { value: SetStateAction<string> } }) => {
                clearErrors('keywords');
                setValueWord(e.target.value);
              },
              placeholder: 'Type a keyword and press enter (maximum 5)',
              onKeyDown: handleKeyPress,
              style: { width: isMobile ? '100%' : '403px' }
            }}
          />
          {errors?.keywords?.message && <TextError text={errors?.keywords?.message} />}
          <Stack flexDirection={'row'} gap={2} flexWrap={'wrap'}>
            {keywords?.length !== 0 &&
              keywords?.map((key, index) => (
                <Stack
                  style={{
                    background: '#B4E465',
                    padding: '8px 10px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '5px',
                    position: 'relative'
                  }}
                  key={index}>
                  <Typography fontSize={'16px'} style={{ color: '#000' }}>
                    {key}
                  </Typography>
                  <Button
                    onClick={() => deleteKey(key)}
                    style={{
                      minWidth: '20px',
                      padding: 0,
                      position: 'absolute',
                      top: -8,
                      right: -8,
                      background: '#fff',
                      borderRadius: 9
                    }}>
                    <HighlightOffIcon fontSize={'small'} />
                  </Button>
                </Stack>
              ))}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
const StyledDatePicker = styled(DatePicker)(({ theme }: StyledParams) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    border: 'none',
    background: '#F8FDE9',
    color: theme.colors.text.base,
    height: '45px',
    overflow: 'hidden',
    marginBottom: '4px',
    marginRight: '4px'
  },
  '& .MuiInputBase-root': {
    border: '1px solid #B4E465',
    boxShadow: `4px 4px 0px 0px  ${theme.colors.background.lightness2}`,
    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: '#B4E465'
    }
  },
  '.MuiFormLabel-root.Mui-focused': {
    display: 'none'
  },
  '.MuiFormLabel-root.MuiFormLabel-filled': {
    display: 'none'
  },
  '.MuiFormLabel-root': {
    fontSize: '16px',
    marginBottom: '8px',
    opacity: '0.6',
    top: '-5px'
  },
  '& fieldset': {
    borderColor: '#B4E465 !important',
    borderWidth: '1px',
    top: 0,
    '& legend': {
      display: 'none'
    }
  }
}));

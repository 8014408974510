import { Stack, Typography } from '@mui/material';
import { useState, useEffect } from 'react';

type CountDownProps = {
  initialCount?: number;
  onFinish: () => void;
};
export const Countdown = ({ initialCount = 3, onFinish }: CountDownProps) => {
  const [count, setCount] = useState(initialCount);
  useEffect(() => {
    let countdownInterval: NodeJS.Timeout | undefined;
    if (count > 0) {
      countdownInterval = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
    }

    return () => clearInterval(countdownInterval);
  }, [count]);

  useEffect(() => {
    if (count === 0) {
      onFinish();
    }
  }, [count, onFinish]);

  return (
    <Stack
      position={'absolute'}
      width={'100%'}
      height={'100%'}
      zIndex={100}
      alignItems={'center'}
      justifyContent={'center'}>
      <Typography fontSize={300} fontWeight={700} color={'#fff'} textAlign={'center'}>
        {count}
      </Typography>
    </Stack>
  );
};

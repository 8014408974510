import * as React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { SelectProps, Stack, styled, Typography, useMediaQuery } from '@mui/material';
import { SelectOptions } from 'app/components/elements/InputSelect';
import { isEmpty } from 'lodash';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

type InputSelectMultipleProps = SelectProps & {
  options: SelectOptions[];
  title?: string;
  onChangeInput?: (value: string[]) => void;
  placeholder?: string;
  defaultValue?: string[];
  label?: string;
  colorlabel?: string;
  isCenterLabel?: boolean;
};

export const InputSelectMultiple = ({
  label,
  colorlabel,
  isCenterLabel,
  options,
  placeholder,
  onChangeInput,
  defaultValue,
  ...rest
}: InputSelectMultipleProps) => {
  const [listChoose, setListChoose] = React.useState<string[]>(defaultValue || []);
  const isMobile = useMediaQuery('(max-width:900px)');

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value }
    } = event;
    onChangeInput && onChangeInput(value as string[]);
    setListChoose(value as string[]);
  };

  return (
    <Stack>
      <FormControl fullWidth>
        <Stack width={'100%'} gap={2.125}>
          {label && (
            <TextStyled
              fontSize={{ xs: '16px', md: '20px' }}
              textAlign={isCenterLabel ? 'center' : 'unset'}
              colorlabel={colorlabel || '#770BA1'}>
              {label}
            </TextStyled>
          )}
          <SelectStyled
            {...rest}
            displayEmpty
            multiple
            value={listChoose}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected: unknown) => {
              if (isEmpty(selected)) {
                return <em style={{ color: 'rgba(104, 104, 104, 0.4)' }}>{placeholder}</em>;
              }
              return (
                <Box
                  sx={{
                    paddingLeft: 0,
                    display: 'flex',
                    gap: 0.5,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    width: isMobile ? 120 : '100%'
                  }}>
                  {(selected as [])?.map((value: unknown, index: number) => {
                    const selectedOption = options.find(
                      (item: SelectOptions) => value === item.value
                    );
                    if (!selectedOption) return null;
                    return (
                      <Chip
                        style={{ color: '#686868', paddingLeft: '5px' }}
                        key={index}
                        label={selectedOption.label}
                        icon={selectedOption.icon}
                      />
                    );
                  })}
                </Box>
              );
            }}
            MenuProps={MenuProps}>
            <MenuItem disabled value="">
              <em>{placeholder}</em>
            </MenuItem>
            {options.map((op: SelectOptions) => (
              <MenuItemStyled key={op.value} value={op.value}>
                <Stack alignItems={'center'} flexDirection={'row'} gap={1.75}>
                  {op?.icon}
                  <TextChilStyled fontSize={{ xs: '16px', md: '20px' }}>{op.label}</TextChilStyled>
                </Stack>
              </MenuItemStyled>
            ))}
          </SelectStyled>
        </Stack>
      </FormControl>
    </Stack>
  );
};
const MenuItemStyled = styled(MenuItem)(() => ({
  padding: '5px 16px'
}));
const TextStyled = styled(Typography)(({ colorlabel }: { colorlabel?: string }) => ({
  fontWeight: 700,
  lineHeight: 'normal',
  color: colorlabel
}));
const TextChilStyled = styled(Typography)(() => ({
  fontWeight: 700,
  lineHeight: 'normal',
  color: '#686868'
}));

const SelectStyled = styled(Select)(() => ({
  height: 48,
  '&.MuiInputBase-root': {
    background: '#F8FDE9',
    borderRadius: '12px',
    boxShadow: '4px 4px 0 0 #B4E465',
    outline: 'none',
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: '#B4E465',
      '.css-14lo706': {
        width: 0
      }
    }
  }
}));

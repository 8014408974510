import { Input, OutlinedInputProps, Stack, styled, Typography } from '@mui/material';
import { ChangeEvent, ReactNode } from 'react';
import { StyledParams } from 'types/common';

export type InputProps = {
  value: string;
  type?: string;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string | number) => void;
  onBlur?: () => void;
  autoComplete?: string;
};

export type RequiredInputProps = InputProps & { required: true };

type InputCustomProps = {
  inputProps: InputProps | RequiredInputProps | OutlinedInputProps;
  label?: string;
  colorlabel?: string;
  isCenter?: boolean;
  type?: string;
  endAdornment?: ReactNode;
};

export const InputCustom = ({
  inputProps,
  label,
  colorlabel,
  isCenter,
  type,
  endAdornment
}: InputCustomProps) => {
  return (
    <Stack
      gap={{ xs: 1.25, md: 2.125 }}
      alignItems={isCenter ? 'center' : 'flex-start'}
      width={{ xs: '100%', md: 'max-content' }}>
      {label && (
        <TextStyled
          fontSize={{ xs: '16px', md: '20px' }}
          textAlign={'center'}
          colorlabel={colorlabel || '#770BA1'}>
          {label}
        </TextStyled>
      )}
      <InputStyled disableUnderline {...inputProps} type={type} endAdornment={endAdornment} />
    </Stack>
  );
};

const TextStyled = styled(Typography)(({ colorlabel }: { colorlabel?: string }) => ({
  fontWeight: 700,
  lineHeight: 'normal',
  color: colorlabel
}));

const InputStyled = styled(Input)(({ theme }: StyledParams) => ({
  border: `1px solid ${theme.colors.background.lightness2}`,
  boxShadow: `4px 4px 0px 0px  ${theme.colors.background.lightness2}`,
  background: theme.colors.background.lightness1,
  height: '45px',
  width: '100%',
  padding: '12px 16px',
  borderRadius: '12px',
  fontSize: '16px',
  color: theme.colors.text.base
}));

import { Stack, styled, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { PrimaryButton } from 'app/components/elements/ButtonCustom';
import { IconRecord } from 'app/components/elements/icons/IconRecord';
import { IconUploadFile } from 'app/components/elements/icons/IconUploadFile';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/user';
import { ROLES } from 'app/pages/Introduction';

type CommentElementProps = {
  isAnswer: boolean;
  onChangeText: (value: string) => void;
  onChangeAttachment: (file: File) => void;
  fileName?: string;
  questionsId?: string;
  onComment: () => void;
  errorComment?: string;
  isLoadingComment?: boolean;
};
export const CommentBlog = ({
  isAnswer,
  questionsId,
  fileName,
  onChangeAttachment,
  onChangeText,
  onComment,
  errorComment,
  isLoadingComment
}: CommentElementProps) => {
  const { user } = useAuth();

  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      onChangeAttachment(fileList[0]);
    }
  };
  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  return (
    <WrapperCommentElement>
      <Stack gap={1.25}>
        <TextTitle>Comment</TextTitle>
        <TextContent
          label="Comment here"
          multiline
          maxRows={20}
          variant="filled"
          onChange={(e) => {
            onChangeText(e.target.value);
          }}
        />
      </Stack>
      <Stack gap={2.5} pb={1.25}>
        <input ref={fileInputRef} hidden onChange={handleFileChange} type="file" />
        <Stack
          flexDirection={'row'}
          gap={1}
          alignItems={'center'}
          sx={{ cursor: 'pointer' }}
          onClick={handleFileClick}>
          <IconUploadFile color={'#fff'} />
          <TextStyled maxWidth={'250px'}>{fileName || 'Add Attachment'}</TextStyled>
        </Stack>
        {!isAnswer && user?.role === ROLES.BLOOMBASSADOR && (
          <Stack
            sx={{ cursor: 'pointer' }}
            flexDirection={'row'}
            gap={1}
            alignItems={'center'}
            onClick={() => navigate(`/dashboard/questions/${questionsId}`)}>
            <IconRecord />
            <TextStyled>Add Recording</TextStyled>
          </Stack>
        )}
        {errorComment && <Typography color={'#fff'}>{errorComment}</Typography>}
        <WrapperButton>
          <PrimaryButton
            disabled={!!errorComment}
            onClick={onComment}
            label={'ADD'}
            fontSizeText={'20px'}
            sx={{ borderRadius: '12px' }}
            isIconFlower
            isLoading={isLoadingComment}
          />
        </WrapperButton>
      </Stack>
    </WrapperCommentElement>
  );
};

const WrapperButton = styled(Stack)(() => ({
  minWidth: 280
}));
const WrapperCommentElement = styled(Stack)(() => ({
  border: '4px solid #C50053',
  borderRadius: '20px',
  backgroundImage: 'linear-gradient(180deg, #FF006B 0%, #FF8D1A 100%)',
  width: '100%',
  height: '100%',
  padding: '18px 22px',
  justifyContent: 'space-between'
}));
const TextContent = styled(TextField)(() => ({
  background: 'none !important',
  fontSize: '18px',
  color: '#fff',
  lineHeight: 'normal',
  '& label': {
    color: '#fff !important'
  }
}));
const TextStyled = styled(Typography)(() => ({
  fontSize: '20px',
  fontWeight: 700,
  color: '#fff',
  lineHeight: 'normal'
}));
const TextTitle = styled(TextStyled)(() => ({
  fontSize: '24px'
}));

type IconWatchFlowerProps = {
  color: string;
};
export const IconWatchFlower = ({ color }: IconWatchFlowerProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.3103 15.4633C39.777 12.2953 41.2728 8.07114 39.7247 4.65393C37.6143 -0.00447458 30.6464 -1.39927 24.1616 1.53856C18.5072 4.10018 15.087 9.10998 15.7028 13.4392C14.7889 12.3831 13.5958 11.4448 12.1803 10.7366C7.76143 8.52595 2.8481 9.3946 1.20605 12.6768C-0.306176 15.6995 1.48345 19.7152 5.22736 22.0579C5.17513 22.0958 5.12305 22.1341 5.07114 22.1729C0.133064 25.8583 -1.47431 32.0559 1.48097 36.0157C4.43625 39.9755 10.8351 40.1979 15.7732 36.5124C16.4606 35.9994 17.0836 35.4376 17.6386 34.8399C16.8349 37.7541 17.2449 40.8162 19.0421 43.2243C22.3876 47.707 29.2734 48.2259 34.4219 44.3835C39.5168 40.581 41.0011 33.9324 37.7893 29.4502C38.1869 29.4083 38.5885 29.3468 38.9922 29.2651C44.1268 28.2258 47.6647 24.2985 46.8945 20.4931C46.1296 16.7142 41.404 14.4766 36.3103 15.4633ZM22.5199 30.7851C29.2034 29.3979 33.9467 25.0224 33.1144 21.0122C32.2821 17.0021 26.1893 14.8757 19.5058 16.2629C12.8223 17.65 8.07896 22.0255 8.91129 26.0357C9.74362 30.0459 15.8364 32.1723 22.5199 30.7851Z"
        fill={color}
      />
    </svg>
  );
};

import { Stack, Typography, useMediaQuery } from '@mui/material';
import { DialogConfirm } from 'app/components/elements/DialogConfirm';
import { useLoading } from 'contexts/loading';
import { useForm } from 'react-hook-form';
import { InputCustom } from 'app/components/elements/InputCustom';
import { PrimaryButton } from 'app/components/elements/ButtonCustom';
import { Link } from 'app/components/elements/Link';
import { useCallback, useState } from 'react';
import { regexEmail } from 'constants/regex';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAsyncCallback } from 'hooks/useAsyncCallback';

type ResetPassword = {
  email: string;
};

export const ForgotPasswordForm = () => {
  const isMobile = useMediaQuery('(max-width:900px)');
  const navigate = useNavigate();
  const { loading } = useLoading();
  const [showModal, setShowModal] = useState<boolean>(false);
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<ResetPassword>({});
  const handleSubmitForm = useCallback(
    async (data: ResetPassword) => {
      await axios.post('/auth/forgot-password', data);
      setShowModal(true);
    },
    // eslint-disable-next-line
    [errors]
  );
  const { asyncCallback: onSubmit } = useAsyncCallback(handleSubmitForm, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2.5}>
        <Stack gap={1}>
          <InputCustom
            label={'Email'}
            inputProps={{
              ...register('email', {
                required: 'Required Field',
                pattern: { value: regexEmail, message: 'Email invalid' }
              }),
              placeholder: 'Enter your email address',
              style: { width: isMobile ? '100%' : '403px' }
            }}
          />
          <Typography color="red" fontSize={'16px'} fontWeight="normal" height={'24px'} ml={1}>
            {errors?.email?.message}
          </Typography>
        </Stack>
        <PrimaryButton
          isLoading={loading}
          type={'submit'}
          label={'Send'}
          fontSizeText={'20px'}
          isIconFlower
          sx={{ borderRadius: '12px' }}
        />
        <Typography
          mt={{ sm: '90px', xs: '150px' }}
          textAlign={'center'}
          color="#770BA1"
          fontSize={'20px'}
          fontWeight={700}
          height={'normal'}>
          <Link to={'/login'}>Login</Link>
        </Typography>
      </Stack>
      <DialogConfirm
        textContent={
          'A password reset link has been sent to your email address. Please check your inbox and follow the instructions to reset your password.\n'
        }
        onClose={() => {
          setShowModal(false);
          navigate('/login');
        }}
        textButton={'Cancel'}
        isOpen={showModal}
        textTitle={'Forgot Password'}
        onSubmit={() => {
          setShowModal(false);
          navigate('/login');
        }}
      />
    </form>
  );
};

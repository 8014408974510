import { Modal, Stack, styled, Typography } from '@mui/material';
import { PrimaryButton } from 'app/components/elements/ButtonCustom';
import { useState } from 'react';
import { StyledParams } from 'types/common';
const contentModal =
  'Lorem ipsum dolor sit amet consectetur. Tincidunt quam amet mattis scelerisque eget tempor id. Sed nisi ultrices pretium tempor fermentum orci fermentum elementum habitasse. Convallis augue ullamcorper amet amet tristique eu nunc sed potenti. Pellentesque non id natoque commodo in dui. Consequat aliquam integer magna et nulla proin. Non amet nec aliquam eget justo dolor pellentesque id. Tincidunt integer habitasse egestas tristique amet volutpat. \n' +
  'Vitae nibh eget ultricies vel. Neque leo ut odio a lacinia odio dolor. Mattis consectetur a mauris facilisis congue rhoncus vestibulum. Amet facilisis semper nibh tellus imperdiet. Massa lacus amet arcu nulla venenatis lorem massa orci. Lacus sed gravida blandit vitae.\n' +
  'Mattis ac egestas arcu ipsum neque ac cras quis fringilla. Proin cras commodo ac vulputate id nunc et erat. Congue morbi fringilla nunc vitae in interdum arcu aliquam. Enim ultrices cum aliquet elementum. Molestie condimentum amet vestibulum pharetra nisl leo in semper. Ac semper ac orci faucibus enim egestas nunc pulvinar. Tempor senectus ut tortor nulla turpis arcu eu fringilla ullamcorper. Commodo semper ac sagittis ultricies. Duis odio lorem turpis hac phasellus urna elit tortor. Aliquet ultricies sit dis dui sed. Ut urna odio tristique risus sit.';
type FormTermProps = {
  onYes: () => void;
  isMobile: boolean;
};
export const FormTerm = ({ onYes, isMobile }: FormTermProps) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  return (
    <Stack width={'100%'} alignItems={'center'}>
      <Wrapper alignItems={'center'}>
        <Typography color={'#839E21'} fontWeight={700} fontSize={'20px'}>
          Terms and Conditions
        </Typography>
        <Typography
          maxWidth={{ xs: '100%', md: 399 }}
          whiteSpace={'pre-line'}
          color={'#839E21'}
          fontWeight={400}>
          I acknowledge that I have read and understand the{' '}
          <b
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => setIsOpenModal(true)}>
            terms and conditions{' '}
          </b>
          outlined in the agreement. By proceeding, I agree to abide by the terms and conditions set
          forth in this document
        </Typography>
        <Stack
          width={'100%'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'center'}
          mt={2.375}>
          <PrimaryButton
            style={{ width: 193 }}
            label={'CONTINUE'}
            onClick={onYes}
            icon={<img src={'icons/icon_next.svg'} />}
          />
        </Stack>
      </Wrapper>
      <ModalStyled open={isOpenModal} onClose={() => setIsOpenModal(false)}>
        <Stack alignItems={'center'} height={'100vh'}>
          <Stack
            width={'100%'}
            alignItems={'center'}
            zIndex={1000}
            maxWidth={'649px'}
            maxHeight={700}
            overflow={'scroll'}
            mt={6.375}
            pb={5}
            px={5}>
            <Typography color={'#839E21'} fontSize={{ xs: '20px', md: '34px' }} fontWeight={840}>
              Terms and Conditions
            </Typography>
            <Typography
              whiteSpace={'pre-line'}
              color={'#839E21'}
              fontSize={{ xs: '16px', md: '20px' }}
              mt={2}
              mb={3.5}>
              {contentModal}
            </Typography>
            <PrimaryButton
              style={{ width: 193 }}
              label={'CLOSE'}
              icon={<img src={'icons/icon_next.svg'} />}
              onClick={() => setIsOpenModal(false)}
            />
          </Stack>
          <Stack width={'100%'} height={'100%'} position={'fixed'} bottom={0}>
            {isMobile ? (
              <img
                width={'100%'}
                height={'100%'}
                alt={'logo'}
                src={'/images/bg-mobile-modal-term.png'}
              />
            ) : (
              <img width={'100%'} height={'100%'} alt={'logo'} src={'/images/bg-modal-term.png'} />
            )}
          </Stack>
        </Stack>
      </ModalStyled>
    </Stack>
  );
};

const Wrapper = styled(Stack)(({ theme }: StyledParams) => ({
  [theme.breakpoints.down(600)]: {
    padding: '27px 30px 35px 30px'
  },
  backgroundImage:
    'linear-gradient(180deg, rgba(255, 141, 26, 0.20) 0%, rgba(255, 90, 159, 0.20) 100%)',
  padding: '40px 100px',
  gap: '17px',
  maxWidth: 590,
  borderRadius: '20px',
  boxShadow: `4px 4px 0px 0px  #B663DD`
}));

const ModalStyled = styled(Modal)(() => ({}));

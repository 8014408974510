import { Stack, styled, Typography } from '@mui/material';
import { renderColorSubject } from 'utils/renderColorSubject';

type RecordUserProps = {
  url: string;
  subjectId?: string;
  isTutorial?: boolean;
};
export const RecordUser = ({ url, subjectId, isTutorial }: RecordUserProps) => {
  return (
    <WrapperImage borderRadius={'20px'} width={'100%'} height={'100%'}>
      <Stack
        borderRadius={'20px'}
        border={`10px solid ${renderColorSubject(Number(subjectId))?.color}`}
        width={'100%'}
        height={'360px'}
        sx={{ background: '#000' }}
        position={'relative'}>
        <video
          style={{ borderRadius: '12px', objectFit: 'cover' }}
          width={'100%'}
          height={'100%'}
          playsInline>
          <source src={url} type="video/mp4" />
        </video>
        {isTutorial && (
          <Stack
            position={'absolute'}
            border={`1px solid ${renderColorSubject(Number(subjectId))?.color}`}
            style={{ background: '#fff', top: 10, right: 10, borderRadius: 10 }}
            px={1}
            py={0.5}>
            <Typography
              style={{
                color: renderColorSubject(Number(subjectId))?.color,
                fontSize: 14,
                fontWeight: 700
              }}>
              Tutorial
            </Typography>
          </Stack>
        )}
      </Stack>
    </WrapperImage>
  );
};

const WrapperImage = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'center'
}));

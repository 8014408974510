export const IconFlowerCustomMap = () => {
  return (
    <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8942 6.74917C13.8137 6.84822 13.7295 6.94208 13.642 7.0307C14.1924 8.77755 13.4492 10.6228 11.9109 11.2297C10.997 11.5904 9.99938 11.4361 9.18178 10.9021C8.85576 11.3956 8.39663 11.7878 7.82941 12.0116C6.22415 12.6451 4.36103 11.6902 3.66801 9.87881C3.56255 9.60315 3.49026 9.32355 3.44915 9.04535C2.83809 8.9939 2.21773 8.77955 1.65822 8.39264C0.0765586 7.29889 -0.41986 5.24032 0.549435 3.79469C1.40025 2.52577 3.09755 2.15087 4.56944 2.81787C4.88482 1.25136 6.12652 0.0820313 7.61025 0.0820313C8.72995 0.0820313 9.71181 0.747958 10.2618 1.74797C11.393 1.1548 12.7364 1.2153 13.6956 2.01964C15.0236 3.13318 15.1125 5.25066 13.8942 6.74917Z"
        fill="#C192D7"
      />
      <ellipse cx="7.18681" cy="5.74939" rx="3.38774" ry="2.15027" fill="#FFBB0B" />
    </svg>
  );
};

import Cookies from 'js-cookie';
import { TypeFormSignUp } from 'app/pages/Introduction';

const ACCESS_TOKEN_KEY = 'accessToken';
const INFO_QUESTIONS_KEY = 'infoQuestionsBloomBox';
const REFRESH_TOKEN_KEY = 'refreshToken';
const ROLE_NAME_KEY = 'role';
const STEP_ACTIVE_KEY = 'stepActive';
const FORM_SIGN_UP_KEY = 'formSignUp';
const EMAIL_KEY = 'email';
const PHONE_KEEY = 'phone';
const AGREE_TERM = 'isAgree';
const FILE_DURATION = 'fileDuration';
const config = {
  secure: process.env.NODE_ENV !== 'development' && process.env.REACT_APP_SECURE_COOKIE === 'true'
};

export type InfoQuestions = {
  id: string;
  fileName: string;
  title: string;
  subjectId: string;
  userId?: string;
};

/**
 * @return TokenStorage service
 */
export class StorageServices {
  /**
   * @returns function handle set token in cookies
   */
  setAccessToken(accessToken: string) {
    Cookies.set(ACCESS_TOKEN_KEY, accessToken, config);
  }

  /**
   * @returns function handle get token in cookies
   */
  getAccessToken(): string | undefined {
    return Cookies.get(ACCESS_TOKEN_KEY);
  }

  /**
   * @returns function handle remove token in cookies
   */
  removeAccessToken() {
    return Cookies.remove(ACCESS_TOKEN_KEY);
  }

  /**
   * @returns function handle set token in cookies
   */
  setFileDuration(fileDuration: string) {
    Cookies.set(FILE_DURATION, fileDuration);
  }

  /**
   * @returns function handle get token in cookies
   */
  getFileDuration(): string | undefined {
    return Cookies.get(FILE_DURATION);
  }

  /**
   * @returns function handle remove token in cookies
   */
  removeFileDuration() {
    return Cookies.remove(FILE_DURATION);
  }
  /**
   * @returns function handle set token in cookies
   */
  setRefreshToken(accessToken: string) {
    Cookies.set(REFRESH_TOKEN_KEY, accessToken, config);
  }

  /**
   * @returns function handle get token in cookies
   */
  getRefreshToken(): string | undefined {
    return Cookies.get(REFRESH_TOKEN_KEY);
  }

  /**
   * @returns function handle remove token in cookies
   */
  removeRefreshToken() {
    return Cookies.remove(REFRESH_TOKEN_KEY);
  }

  /**
   * @returns function handle set roleName in cookies
   */
  setRoleName(roleName: string) {
    Cookies.set(ROLE_NAME_KEY, roleName);
  }
  getRoleName(): string | undefined {
    return Cookies.get(ROLE_NAME_KEY);
  }

  removeRoleName() {
    return Cookies.remove(ROLE_NAME_KEY);
  }

  setFormSignUp(value: TypeFormSignUp) {
    Cookies.set(FORM_SIGN_UP_KEY, JSON.stringify(value));
  }

  getFormSignUp() {
    return Cookies.get(FORM_SIGN_UP_KEY);
  }

  removeFormSignUp() {
    return Cookies.remove(FORM_SIGN_UP_KEY);
  }

  setStepIntroductions(stepActive: number) {
    Cookies.set(STEP_ACTIVE_KEY, stepActive.toString());
  }

  getStepIntroductions() {
    return Number(Cookies.get(STEP_ACTIVE_KEY));
  }

  removeStepIntroductions() {
    return Cookies.remove(STEP_ACTIVE_KEY);
  }

  setEmailCookie(email: string) {
    Cookies.set(EMAIL_KEY, email);
  }

  getEmailCookie() {
    return Cookies.get(EMAIL_KEY);
  }
  setPhoneCookie(phone: string) {
    Cookies.set(PHONE_KEEY, phone);
  }

  getPhoneCookie() {
    return Cookies.get(PHONE_KEEY);
  }
  removePhoneCookie() {
    return Cookies.remove(PHONE_KEEY);
  }
  setInfoQuestionsCookie(infoQuestions?: InfoQuestions[]) {
    if (!infoQuestions) return;
    Cookies.set(INFO_QUESTIONS_KEY, JSON.stringify(infoQuestions));
  }

  getInfoQuestionsCookie(): InfoQuestions[] {
    const info = Cookies.get(INFO_QUESTIONS_KEY);
    if (!info) {
      return [];
    }
    return JSON.parse(Cookies.get(INFO_QUESTIONS_KEY) || '');
  }

  removeInfoQuestionsCookie() {
    return Cookies.remove(INFO_QUESTIONS_KEY);
  }

  removeEmailCookie() {
    return Cookies.remove(EMAIL_KEY);
  }
  setIsAgreeTerm(isAgree: string) {
    Cookies.set(AGREE_TERM, isAgree);
  }

  getIsAgreeTerm() {
    return Cookies.get(AGREE_TERM);
  }

  removeIsAgreeTerm() {
    return Cookies.remove(AGREE_TERM);
  }
}

import { Stack, Typography } from '@mui/material';
import axios from 'axios';
import { useFlash } from 'contexts/flash';
import dayjs from 'dayjs';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { StorageServices } from 'services/storage';
import { User } from 'types/User';
import { FormProfile } from 'app/pages/ProfilePage/components/FormProfile';
import { isEmpty, pick } from 'lodash';
import { Loading } from 'app/components/elements/Loading';
import { useAuth } from 'contexts/user';
import { unregister } from '../../../serviceWorkerRegistration';

export type ProfileUser = {
  avatar: string;
  name: string;
  email: string;
  country: string;
  keywords?: string[];
  city: string;
  birthday: string;
  school?: string;
  major?: string;
  subjects?: string[];
  currentPassword?: string;
  newPassword?: string;
  avatarFile?: File;
};
const convertData = (user: User) => {
  const optionCountry = {
    value: user.address.countryCode,
    label: user.address.country
  };
  const optionCity = {
    value: user.address.city,
    label: user.address.city
  };
  const optionSubject = user?.subjects.map((item) => item.id);
  return {
    avatar: user.avatar,
    name: user.name,
    email: user.email,
    keywords: user.keywords,
    country: optionCountry.label,
    city: optionCity.label,
    birthday: dayjs(user.birthday).format('DD/MM/YYYY'),
    school: user?.school || undefined,
    major: user?.major?.id || undefined,
    subjects: optionSubject || undefined,
    currentPassword: '',
    newPassword: ''
  };
};
const storage = new StorageServices();

export const ProfilePage = () => {
  const navigate = useNavigate();

  const { setFlash } = useFlash();
  const { user, setUser } = useAuth();
  const userProfile = useMemo(() => {
    if (!user) return;
    return convertData(user);
  }, [user]);
  const handleLogout = useCallback(() => {
    unregister();
    storage.removeAccessToken();
    storage.removeRefreshToken();
    navigate('/login');
    // eslint-disable-next-line
  }, []);
  const handlePutProfile = useCallback(
    async (value: ProfileUser, isLogout?: boolean) => {
      let fileAvatar;
      if (value.avatarFile) {
        const formData = new FormData();
        formData.append('avatar', value.avatarFile);

        fileAvatar = await axios.post('/storages/users', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      }
      const payload = {
        ...pick(value, ['currentPassword', 'newPassword', 'major', 'subjects', 'keywords']),
        keywords: value?.keywords,
        majorId: value.major ? value.major : undefined,
        email: value.email === userProfile?.email ? undefined : value.email,
        avatarId: fileAvatar ? fileAvatar.data.avatar.id : undefined,
        birthday: dayjs(value.birthday).format('YYYY-MM-DD'),
        currentPassword: value.currentPassword ? value.currentPassword : undefined,
        newPassword: value.newPassword ? value.newPassword : undefined
      };
      const res = await axios.put('/auth/me', payload);
      setUser(res.data.user);
      setFlash({ type: 'success', message: 'update info successfully' });
      if (isLogout) {
        handleLogout();
      }
    },
    [handleLogout, userProfile?.email]
  );
  const { asyncCallback: handleChangeProfile } = useAsyncCallback(handlePutProfile, []);
  return (
    <Stack
      pt={{ xs: 5, lg: 13.25 }}
      pl={{ xs: 0, lg: 5 }}
      width={{ xs: '100%', lg: '1100px' }}
      overflow={{ xs: 'unset', lg: 'scroll' }}
      px={{ xs: 2, lg: 'unset' }}>
      <Typography color={'#770BA1'} fontSize={'30px'} fontWeight={700} lineHeight={'normal'}>
        Profile
      </Typography>
      {isEmpty(userProfile) ? (
        <Loading />
      ) : (
        <FormProfile handleChangeProfile={handleChangeProfile} initValue={userProfile} />
      )}
    </Stack>
  );
};

import { Stack, useMediaQuery } from '@mui/material';

import { LayoutLanding } from 'app/components/templates/LayoutLanding';
import { FormSignUp } from 'app/pages/SignUp/components/FormSignUp';

export const SignUpPage = () => {
  const isMobile = useMediaQuery('(max-width:900px)');

  return (
    <Stack alignItems={'center'} width={'100%'}>
      <LayoutLanding bg={isMobile ? '/images/bg-landing-mobile-1.png' : '/images/bg-landing-1.png'}>
        <Stack mt={8.375} mb={3.5} width={'100%'} alignItems={'center'}>
          <Stack width={{ sm: '400px', xs: '247px' }}>
            <img width={'100%'} height={'100%'} alt={'logo'} src={'/images/logo-login.png'} />
          </Stack>
          <Stack
            px={{ xs: 3.125, sm: 0 }}
            mt={3}
            width={'100%'}
            maxWidth={{ xs: 'unset', sm: 402 }}>
            <FormSignUp />
          </Stack>
        </Stack>
      </LayoutLanding>
    </Stack>
  );
};

import { useState } from 'react';
import { IconButton, InputAdornment, Stack } from '@mui/material';
import { InputCustom } from 'app/components/elements/InputCustom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useFormContext } from 'react-hook-form';
import { TypeFormSignUp } from 'app/pages/Introduction';
import { TextError } from 'app/components/elements/TextError';

type FormPasswordProps = {
  isMobile: boolean;
  activeStep: number;
};
export const FormPassword = ({ isMobile, activeStep }: FormPasswordProps) => {
  const {
    register,
    getValues,
    formState: { errors }
  } = useFormContext<TypeFormSignUp>();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  return (
    <Stack minWidth={isMobile ? '100%' : '402px'} alignItems={'center'} gap={'19px'}>
      <Stack width={{ xs: '230px', md: '300px' }} alignItems={'center'}>
        <img width={'100%'} height={'100%'} alt={'logo'} src={'/images/logo-login.png'} />
      </Stack>
      <Stack width={'100%'} gap={'19px'}>
        <Stack gap={1} width={'100%'} alignItems={'center'}>
          <InputCustom
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            type={showPassword ? 'text' : 'password'}
            label={'Password'}
            inputProps={{
              ...register('password', {
                required: {
                  value: activeStep === 0,
                  message: 'Password required'
                },
                minLength: {
                  value: 8,
                  message: 'Password invalid'
                }
              }),
              placeholder: 'Enter your password',
              style: { width: isMobile ? '100%' : '403px' }
            }}
          />
          <TextError text={errors?.password?.message || ''} />
        </Stack>
        <Stack gap={1} width={'100%'} alignItems={'center'}>
          <InputCustom
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label={'Confirm Password'}
            type={showConfirmPassword ? 'text' : 'password'}
            inputProps={{
              ...register('confirmPassword', {
                validate: (value: string) =>
                  value === getValues('password') || 'The passwords do not match'
              }),
              placeholder: 'Enter your confirmPassword',
              style: { width: isMobile ? '100%' : '403px' }
            }}
          />
          {errors?.confirmPassword?.message && (
            <TextError text={errors?.confirmPassword?.message} />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

import { Stack, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { StorageServices } from 'services/storage';

type RenderTimeProps = {
  isActive: boolean;
  color?: string;
  fontSize?: string;
};
const storage = new StorageServices();
export const RenderTime = ({ isActive, color, fontSize }: RenderTimeProps) => {
  const [second, setSecond] = useState<string>('00');
  const [minute, setMinute] = useState<string>('00');
  const [counter, setCounter] = useState<number>(0);
  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    if (isActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        const computedSecond =
          String(secondCounter).length === 1 ? `0${secondCounter}` : String(secondCounter);
        const computedMinute =
          String(minuteCounter).length === 1 ? `0${minuteCounter}` : String(minuteCounter);

        setSecond(computedSecond);
        setMinute(computedMinute);

        setCounter((counter) => counter + 1);
        storage.setFileDuration(counter.toString());
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, counter]);
  return (
    <Stack flexDirection={'row'}>
      <TextStyled color={color || '#D090D7'} fontSize={fontSize || '48px'}>
        {minute}:
      </TextStyled>
      <TextStyled color={color || '#D090D7'} fontSize={fontSize || '48px'}>
        {second}
      </TextStyled>
    </Stack>
  );
};

const TextStyled = styled(Typography)(() => ({
  fontWeight: 700
}));

import { Stack } from '@mui/material';

type IconFlowerNoCircleProps = {
  color?: string;
  width?: string;
  height?: string;
};
export const IconFlowerNoCircle = ({ color, width, height }: IconFlowerNoCircleProps) => {
  return (
    <Stack width={`${width}px` || '33px'} height={`${height}px` || '33px'}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || '33px'}
        height={width || '33px'}
        viewBox="0 0 47 47"
        fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M36.311 15.4627C39.7772 12.2947 41.2726 8.0709 39.7246 4.65393C37.6142 -0.00447455 30.6464 -1.39927 24.1616 1.53856C18.5073 4.10015 15.0871 9.10988 15.7028 13.4391C14.7889 12.3829 13.5958 11.4447 12.1803 10.7365C7.76143 8.52586 2.8481 9.3945 1.20605 12.6767C-0.306122 15.6993 1.48333 19.7148 5.22697 22.0576C5.17487 22.0954 5.12293 22.1336 5.07114 22.1722C0.133064 25.8577 -1.47431 32.0553 1.48097 36.0151C4.43625 39.9748 10.8351 40.1972 15.7732 36.5118C16.4606 35.9988 17.0835 35.4371 17.6385 34.8395C16.8349 37.7536 17.2449 40.8155 19.0421 43.2236C22.3876 47.7062 29.2733 48.2252 34.4218 44.3827C39.5166 40.5803 41.001 33.9318 37.7894 29.4497C38.187 29.4077 38.5886 29.3463 38.9922 29.2646C44.1268 28.2253 47.6647 24.298 46.8945 20.4926C46.1297 16.7139 41.4045 14.4763 36.311 15.4627ZM22.5198 30.7845C29.2033 29.3973 33.9467 25.0219 33.1143 21.0117C32.282 17.0015 26.1892 14.8751 19.5057 16.2623C12.8222 17.6495 8.07887 22.0249 8.9112 26.0351C9.74353 30.0453 15.8363 32.1717 22.5198 30.7845Z"
          fill={color || 'white'}
        />
      </svg>
    </Stack>
  );
};

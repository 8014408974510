import { Button, Stack, styled, Typography, useMediaQuery } from '@mui/material';
import { BloombassadorButton, PrimaryButton } from 'app/components/elements/ButtonCustom';
import { useRef, useEffect, FC, useState, useCallback, useMemo } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';
import { LocationAddress } from 'types/questions';
import { RenderTime } from 'utils/timeCount';
import { DialogConfirm } from 'app/components/elements/DialogConfirm';
import { renderColorSubject } from 'utils/renderColorSubject';
import { Countdown } from 'utils/countDown';

type VideoPreviewProps = {
  stream: MediaStream | null;
};

export const RECORDSTATUS = {
  IDLE: 'idle',
  RECORDING: 'recording',
  STOPPED: 'stopped'
};

const VideoPreview: FC<VideoPreviewProps> = ({ stream }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  if (!stream) {
    return null;
  }

  return <video width={'100%'} height={'100%'} ref={videoRef} playsInline autoPlay muted />;
};

type RecordVideoProps = {
  onSubmit: (value?: string, location?: LocationAddress) => void;
  subjectId?: string;
  handleCancel: () => void;
  isAnswer?: boolean;
  isTutorial?: boolean;
};
export const RecordVideo = ({
  onSubmit,
  subjectId,
  handleCancel,
  isAnswer,
  isTutorial
}: RecordVideoProps) => {
  const isMobile = useMediaQuery('(max-width:900px)');
  const { previewStream, status, startRecording, stopRecording, mediaBlobUrl } =
    useReactMediaRecorder({
      video: true,
      audio: true
    });
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const videoEndRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [startCount, setStartCount] = useState<boolean>(false);
  const handlePlayPause = (): void => {
    const video = videoEndRef.current;
    if (video?.paused || video?.ended) {
      video?.play();
      setIsPlaying(true);
    } else {
      video?.pause();
      setIsPlaying(false);
    }
  };

  const timeVideo: number = useMemo(() => {
    if (isTutorial) return 5;
    return 1;
  }, [isTutorial]);

  useEffect(() => {
    if (mediaBlobUrl) {
      onSubmit(mediaBlobUrl);
    }
    // eslint-disable-next-line
  }, [mediaBlobUrl]);

  useEffect(() => {
    if (status === RECORDSTATUS.RECORDING) {
      setIsActive(true);
      setTimeout(
        () => {
          handleStopRecordVideo();
        },
        timeVideo * 60 * 1000
      );
    }
    return;
  }, [status]);
  const handleStartRecordVideo = useCallback(() => {
    startRecording();
    setStartCount(false);
    // eslint-disable-next-line
  }, []);
  const handleStopRecordVideo = useCallback(() => {
    stopRecording();
    setIsActive(false);
    setIsOpenModal(true);
    // eslint-disable-next-line
  }, []);

  const handleChooseStart = useCallback(async () => {
    setStartCount(true);
  }, []);
  const renderButton = () => {
    if (status === RECORDSTATUS.IDLE)
      return (
        <WrapperButton onClick={handleChooseStart}>
          <ButtonStyled endIcon={<img src={'/icons/icon_record-start.svg'} />} />
          <Typography color={'#AECC44'}>Start Recording</Typography>
        </WrapperButton>
      );
    if (status === RECORDSTATUS.RECORDING)
      return (
        <WrapperButton onClick={handleStopRecordVideo}>
          <ButtonStyled endIcon={<img src={'/icons/icon_record-stop.svg'} />} />
          <Typography color={'#AECC44'}>Recording in Progress</Typography>
        </WrapperButton>
      );
    if (status === RECORDSTATUS.STOPPED)
      return (
        <WrapperButton onClick={handlePlayPause}>
          <ButtonStyled endIcon={<img src={'/icons/icon_record-play.svg'} />} />
          <Typography color={'#AECC44'}>Play</Typography>
        </WrapperButton>
      );
    return null;
  };

  return (
    <Stack width={'100%'}>
      {isMobile ? (
        <Stack
          position={'fixed'}
          width={'100vw'}
          height={'100vh'}
          right={0}
          top={0}
          zIndex={10}
          sx={{ background: '#000' }}
          border={`12px solid ${renderColorSubject(subjectId)}`}>
          {status === 'stopped' ? (
            <Stack
              style={{ overflow: isMobile ? 'hidden' : 'unset' }}
              position={'absolute'}
              width={'100%'}
              height={'100vh'}>
              <video
                playsInline
                muted
                ref={videoEndRef}
                width={'100%'}
                height={'100%'}
                src={mediaBlobUrl}
                autoPlay={isPlaying}
              />
            </Stack>
          ) : (
            <Stack position={'absolute'} width={'100%'} height={'100%'}>
              <VideoPreview stream={previewStream} />
            </Stack>
          )}
          {startCount && <Countdown onFinish={handleStartRecordVideo} />}
          <Stack height={'100vh'} justifyContent={'space-between'} alignItems={'center'}>
            <Stack />
            <Stack
              px={2.5}
              width={'100%'}
              mb={'50px'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'flex-end'}>
              <Stack width={50} height={1} />
              {renderButton()}
              <RenderTime color={'#fff'} isActive={isActive} fontSize={'20px'} />
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack width={'100%'} alignItems={'center'}>
          <WrapperRecordVideo border={`30px solid ${renderColorSubject(subjectId)?.color}`}>
            {status === 'stopped' ? (
              <video
                ref={videoEndRef}
                width={'100%'}
                height={'100%'}
                src={mediaBlobUrl}
                autoPlay={isPlaying}
              />
            ) : (
              <VideoPreview stream={previewStream} />
            )}
            {startCount && <Countdown onFinish={handleStartRecordVideo} />}
          </WrapperRecordVideo>
          <Stack
            mt={2.5}
            width={'100%'}
            justifyContent={'space-between'}
            flexDirection={'row'}
            alignItems={'center'}>
            <Stack width={132} />
            <Stack maxWidth={200}>{renderButton()}</Stack>
            <RenderTime isActive={isActive} />
          </Stack>
          {!mediaBlobUrl && (
            <Stack width={'100%'} alignItems={'flex-end'} mt={1.25}>
              {isAnswer ? (
                <BloombassadorButton
                  isOutline
                  label={'Cancel'}
                  onClick={() => {
                    stopRecording();
                    handleCancel();
                  }}
                  sx={{ width: 170 }}
                />
              ) : (
                <PrimaryButton
                  isOutline
                  label={'Cancel'}
                  onClick={() => {
                    stopRecording();
                    handleCancel();
                  }}
                  sx={{ width: 170, borderRadius: '12px' }}
                />
              )}
            </Stack>
          )}
        </Stack>
      )}
      <DialogConfirm
        isOpen={isOpenModal}
        textButton={'PLAYBACK'}
        textTitle={'Nicely Done'}
        textContent={'You have successfully recorded the video. \n' + 'Want to play it back?'}
        onSubmit={() => setIsOpenModal(false)}
        onClose={() => setIsOpenModal(false)}
      />
    </Stack>
  );
};

const WrapperRecordVideo = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: 572,
  borderRadius: '30px',
  background: '#000',
  position: 'relative',
  [theme.breakpoints.up('xl')]: {
    height: '70vh'
  }
}));
const WrapperButton = styled(Stack)(() => ({
  width: '100%',
  alignItems: 'center',
  gap: '10px',
  justifyContent: 'center'
}));
const ButtonStyled = styled(Button)(() => ({
  padding: 0,
  '.MuiButton-endIcon': {
    margin: 0
  }
}));

import { Stack, Typography } from '@mui/material';
import { SecondaryButton } from 'app/components/elements/ButtonCustom';
import { useLoading } from 'contexts/loading';
const questions =
  '<b>Bloombassador</b>: This is placeholder text to define what Bloombassador is. This is placeholder text.\n' +
  '<span style="padding-left: 4px; padding-bottom: 9px"; padding-top : 8px>• This is placeholder text.</span>\n' +
  '<span style="padding-left: 4px; padding-bottom: 9px">• This is placeholder text.</span>\n' +
  '<span style="padding-left: 4px; padding-bottom: 9px">• This is placeholder text.</span>';

type FormQuestionProps = {
  onYes: () => void;
  onNo: () => void;
  isMobile: boolean;
};
export const FormQuestion = ({ onYes, onNo, isMobile }: FormQuestionProps) => {
  const { loading } = useLoading();
  return (
    <Stack width={'100%'} alignItems={'center'} mt={{ xs: '130px', md: 'unset' }}>
      <Stack maxWidth={isMobile ? '100%' : 400} alignItems={'center'} gap={'17px'}>
        <Typography color={'#839E21'} fontWeight={700} fontSize={'20px'}>
          Would you like to be a Bloombassador?
        </Typography>
        <Typography
          whiteSpace={'pre-line'}
          color={'#839E21'}
          fontWeight={400}
          ml={'27px'}
          dangerouslySetInnerHTML={{ __html: questions }}
        />
        <Stack
          width={'100%'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          mt={2.375}
          gap={isMobile ? '20px' : 'unset'}>
          <SecondaryButton
            isLoading={loading}
            colorButton={'#770BA1'}
            onClick={onYes}
            label={'Yes'}
            style={{ width: isMobile ? '100%' : 193 }}
          />
          <SecondaryButton
            isLoading={loading}
            colorButton={'#770BA1'}
            isOutline
            label={'No'}
            onClick={onNo}
            style={{ width: isMobile ? '100%' : 193 }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

import { Stack } from '@mui/material';
import { LayoutRole } from 'app/components/templates/LayoutRole';
import { Header } from 'app/pages/AdminQuestionsPage/components/AdminQuestionsTable';
import { AdminUserTable } from 'app/pages/AdminUserPage/components/AdminUserTable';
import { ROLES } from 'app/pages/Introduction';
import axios from 'axios';
import { SIZE_ADMIN_QUESTIONS } from 'constants/paging';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { usePaging } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { User } from 'types/User';

const HEADER_QUESTIONS: Header[] = [
  {
    label: 'Name',
    width: '70px'
  },
  {
    label: 'Email Address'
  },
  {
    label: 'Phone Number'
  },
  {
    label: 'Birthday',
    width: '130px'
  },
  {
    label: 'Keywords',
    width: '130px'
  },
  {
    label: 'Interests'
  },
  {
    label: 'Location',
    width: '10%'
  },
  {
    label: 'Points'
  },
  {
    label: 'Role'
  }
];
export const AdminUserPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [listUser, setListUser] = useState<User[]>([]);
  const { pagingInfo, setCurrentPage, setTotal } = usePaging({
    rowsPerPage: SIZE_ADMIN_QUESTIONS
  });
  const handleChangePage = useCallback((value: number) => {
    setCurrentPage(value);
    setSearchParams({ page: value.toString() });
    // eslint-disable-next-line
  }, []);

  const fetchDataListUser = useCallback(async (page: string) => {
    const res = await axios.get(`/admin/users?page=${page || 1}&size=${SIZE_ADMIN_QUESTIONS}`);
    setListUser(res.data.users as User[]);
    setCurrentPage(Number(page));
    setTotal(res.data.total);
    // eslint-disable-next-line
  }, []);
  const { asyncCallback: handleFetchData } = useAsyncCallback(fetchDataListUser, []);
  useEffect(() => {
    handleFetchData(searchParams.get('page') || 1);
    // eslint-disable-next-line
  }, [searchParams.get('page')]);

  return (
    <LayoutRole roles={[ROLES.ADMIN]}>
      <Stack width={'100%'}>
        <AdminUserTable
          headers={HEADER_QUESTIONS}
          listUser={listUser}
          pagination={pagingInfo}
          handleChangePage={handleChangePage}
        />
      </Stack>
    </LayoutRole>
  );
};

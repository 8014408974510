import { Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';

/**
 * @returns Component Not Found Page
 */
export function NotFoundPage() {
  return (
    <>
      <Helmet>
        <title>404 Page Not Found</title>
        <meta content="Page not found" name="description" />
      </Helmet>
      <Stack width={'100%'} flex={1} alignItems={'center'} justifyContent={'center'}>
        <Typography>😢 Page not found.</Typography>
      </Stack>
    </>
  );
}

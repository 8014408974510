import { Stack } from '@mui/material';
import { LayoutRole } from 'app/components/templates/LayoutRole';
import {
  AdminQuestionsTable,
  Header
} from 'app/pages/AdminQuestionsPage/components/AdminQuestionsTable';
import { ROLES } from 'app/pages/Introduction';
import axios from 'axios';
import { SIZE_ADMIN_QUESTIONS } from 'constants/paging';
import { useFlash } from 'contexts/flash';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { usePaging } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Questions } from 'types/questions';

const HEADER_QUESTIONS: Header[] = [
  {
    label: 'Name',
    width: '20%'
  },
  {
    label: 'Email',
    width: '20%'
  },
  {
    label: 'Questions'
  },
  {
    label: 'Action',
    width: '200px'
  }
];
export const AdminQuestionsPage = () => {
  const { setFlash } = useFlash();
  const [searchParams, setSearchParams] = useSearchParams();
  const [listQuestions, setListQuestions] = useState<Questions[]>([]);
  const { pagingInfo, setCurrentPage, setTotal } = usePaging({
    rowsPerPage: SIZE_ADMIN_QUESTIONS
  });
  const handleChangePage = useCallback((value: number) => {
    setCurrentPage(value);
    setSearchParams({ page: value.toString() });
    // eslint-disable-next-line
  }, []);

  const fetchDataListUser = useCallback(async (page: string) => {
    const res = await axios.get(`/admin/questions?page=${page || 1}&size=${SIZE_ADMIN_QUESTIONS}`);
    setListQuestions(res.data.result as Questions[]);
    setCurrentPage(Number(page));
    setTotal(res.data.total);
    // eslint-disable-next-line
    }, []);
  const { asyncCallback: handleFetchData } = useAsyncCallback(fetchDataListUser, []);
  useEffect(() => {
    handleFetchData(searchParams.get('page') || 1);
    // eslint-disable-next-line
  }, [searchParams.get('page')]);

  const handleApprove = useCallback(
    async (id: string) => {
      const { data: res } = await axios.put(`/admin/questions/${id}/approve`, { isApprove: true });
      if (!res.result) return;
      const newList = listQuestions.map((item) =>
        item.id === id ? { ...item, isApproved: true } : item
      );
      setListQuestions(newList);
      setFlash({ type: 'success', message: 'Approve Questions Video Successfully' });
    },
    [listQuestions]
  );
  const handleOnReject = useCallback(
    async (id: string) => {
      const { data: res } = await axios.put(`/admin/questions/${id}/approve`, { isApprove: false });
      if (!res.result) return;
      const newList = listQuestions.filter((item) => item.id !== id);
      setListQuestions(newList);
      setFlash({ type: 'success', message: 'Approve Questions Video Successfully' });
      if (newList.length === 0) {
        const page = searchParams.get('page');
        const newPage = Number(page) - 1;
        setSearchParams({ page: newPage.toString() });
      }
    },
    [listQuestions, searchParams.get('page')]
  );

  const { asyncCallback: asyncHandleApprove } = useAsyncCallback(handleApprove, []);
  const { asyncCallback: asyncHandleReject } = useAsyncCallback(handleOnReject, []);

  return (
    <LayoutRole roles={[ROLES.ADMIN]}>
      <Stack width={'100%'}>
        <AdminQuestionsTable
          headers={HEADER_QUESTIONS}
          onApprove={asyncHandleApprove}
          onReject={asyncHandleReject}
          listQuestions={listQuestions}
          pagination={pagingInfo}
          handleChangePage={handleChangePage}
        />
      </Stack>
    </LayoutRole>
  );
};

import { Button, Stack, styled, Typography } from '@mui/material';
import { useAuth } from 'contexts/user';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { BlogPost } from 'app/components/elements/BlogPost';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularProgress from '@mui/material/CircularProgress';
import { useSearchParams } from 'react-router-dom';
import { Questions } from 'types/questions';

const SIZE_PAGE = 10;
/**
 * @returns Component Home Page
 */
export function HomePage() {
  const { user } = useAuth();
  const [dataPost, setDataPost] = useState<Questions[]>([]);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const fetchNextListPost = useCallback(async () => {
    const queryBy =
      searchParams.get('activeBy') === 'recommends' ? 'questions/recommends' : 'questions';
    const { data: res } = await axios.get(`/${queryBy}?page=${page}&size=${SIZE_PAGE}`);
    const newData = res.result as Questions[];
    const dataConvert = newData.filter((item) => !item.isTutorial);
    if (newData.length > 0) {
      setDataPost([...dataPost, ...dataConvert]);
      setPage((prevState) => prevState + 1);
      return;
    }
    setHasMore(false);
    // eslint-disable-next-line
  }, [page, searchParams.get('activeBy')]);

  const fetchListPost = useCallback(async () => {
    const queryBy =
      searchParams.get('activeBy') === 'recommends' ? 'questions/recommends' : 'questions';
    const { data: res } = await axios.get(`/${queryBy}?page=1&size=${SIZE_PAGE}`);
    const newData = res.result as Questions[];
    const dataConvert = newData.filter((item) => !item.isTutorial);
    setDataPost(dataConvert);
    setPage(2);
    // eslint-disable-next-line
  }, [page, searchParams.get('activeBy')]);

  const { asyncCallback: fetchDataList } = useAsyncCallback(fetchListPost, []);
  useEffect(() => {
    fetchDataList();
  }, [searchParams.get('activeBy')]);

  return (
    <Stack width={'100%'} alignItems={'flex-start'}>
      <Wrapper
        pt={{ xs: 2.5, md: 7.5 }}
        pl={{ xs: 2.5, md: 7.25 }}
        width={{ xs: window.innerWidth, md: '100%' }}>
        {user?.role === 'STUDENT' && (
          <WrapperButton>
            <ButtonStyled
              active={searchParams.get('activeBy') !== 'recommends'}
              onClick={() => setSearchParams({ activeBy: 'questions' })}>
              <TextButtonStyled active={searchParams.get('activeBy') !== 'recommends'}>
                Recent
              </TextButtonStyled>
            </ButtonStyled>
            <ButtonStyled
              active={searchParams.get('activeBy') === 'recommends'}
              onClick={() => setSearchParams({ activeBy: 'recommends' })}>
              <TextButtonStyled active={searchParams.get('activeBy') === 'recommends'}>
                Recommended
              </TextButtonStyled>
            </ButtonStyled>
          </WrapperButton>
        )}
        {dataPost.length === 0 ? (
          <Stack />
        ) : (
          <InfiniteScroll
            style={{ overflow: 'unset' }}
            dataLength={dataPost.length}
            next={fetchNextListPost}
            hasMore={dataPost.length >= SIZE_PAGE * (page - 1) ? hasMore : false}
            loader={<CircularProgress />}>
            {dataPost.map((item) => (
              <BlogPost key={item.id} blog={item} />
            ))}
          </InfiniteScroll>
        )}
      </Wrapper>
    </Stack>
  );
}

const Wrapper = styled(Stack)(() => ({
  width: 'fit-content'
}));

const WrapperButton = styled(Stack)(() => ({
  flexDirection: 'row',
  gap: '10px',
  marginBottom: '40px'
}));

const ButtonStyled = styled(Button)(({ active }: { active: boolean }) => ({
  padding: '12px 16px',
  borderRadius: '16px',
  border: '1px solid #D090D7',
  background: active ? '#D090D7' : '#fff',
  '&:hover': {
    background: active ? '#D090D7' : '#fff',
    opacity: 0.8
  },
  minWidth: '170px'
}));

const TextButtonStyled = styled(Typography)(({ active }: { active: boolean }) => ({
  color: active ? '#fff' : '#D090D7',
  fontSize: '16px'
}));

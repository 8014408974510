import { motion } from 'framer-motion';
import { Stack, styled } from '@mui/material';

export const ExplodingAnimationFlower = () => {
  const generateRandomNumber = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
  };

  const flowers = [];
  const flowerTypes = [
    '/images/animation_flower1.png',
    '/images/animation_flower2.png',
    '/images/animation_flower3.png',
    '/images/animation_flower4.png',
    '/images/animation_flower5.png'
  ];
  let flowerIndex = 0;
  const numRows = 10;
  const numCols = 17;
  const horizontalSpacing = 120;
  const verticalSpacing = 150;

  for (let row = 0; row < numRows; row++) {
    for (let col = 0; col < numCols; col++) {
      flowers.push(
        <motion.div
          key={`${row}-${col}`}
          style={{
            position: 'absolute',
            top: `${row * verticalSpacing}px`,
            left: `${col * horizontalSpacing}px`,
            zIndex: 100
          }}
          animate={{
            y: [-200, 0],
            opacity: [0, 1],
            scale: [0, generateRandomNumber(0.5, 1.5), 1],
            rotate: generateRandomNumber(0, 360)
          }}
          transition={{ duration: generateRandomNumber(0.5, 2), ease: 'easeInOut' }}>
          <img src={flowerTypes[flowerIndex]} alt={`flower-${flowerIndex}`} />
        </motion.div>
      );
      flowerIndex = (flowerIndex + 1) % flowerTypes.length;
    }
  }
  const ContainerStyle = styled(Stack)(() => ({
    position: 'fixed',
    top: 0,
    left: 0,
    width: `100vw`,
    height: `100vh`,
    overflow: 'hidden'
  }));
  return <ContainerStyle>{flowers}</ContainerStyle>;
};

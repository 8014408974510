import { Stack, styled } from '@mui/material';
import { Subject } from 'types/User';
import { renderBackGroundMultiColorSubject, renderColorSubject } from 'utils/renderColorSubject';

type ImageUserProps = {
  url?: string;
  subjects: Subject[];
  isCircle?: boolean;
  borderWidth?: string;
};
export const ImageUser = ({ url, subjects, isCircle, borderWidth = '10px' }: ImageUserProps) => {
  return (
    <WrapperImage
      borderRadius={isCircle ? '50%' : '20px'}
      width={'100%'}
      height={'100%'}
      sx={{
        cursor: 'pointer',
        backgroundImage: renderBackGroundMultiColorSubject(subjects),
        backgroundColor:
          subjects.length === 1 && !!subjects[0]
            ? renderColorSubject(Number(subjects[0].id))?.color
            : 'unset'
      }}>
      <Stack border={`${borderWidth} solid transparent`} width={'100%'} height={'100%'}>
        <img
          style={{ borderRadius: isCircle ? '50%' : '20px', objectFit: 'cover' }}
          width={'100%'}
          height={'100%'}
          alt={'logo'}
          src={url || '/images/image-user-df.jpg'}
        />
      </Stack>
    </WrapperImage>
  );
};

const WrapperImage = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'center'
}));

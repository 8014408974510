import { Button, IconButton, Stack, styled, Typography, useMediaQuery } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ImageUser } from 'app/components/elements/ImageUser';
import { TruncateTableText } from 'app/components/elements/TruncateTableText';
import axios from 'axios';
import dayjs from 'dayjs';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { isEmpty, reject } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Questions } from 'types/questions';
import { CountdownTimer } from 'utils/countDownHourDate';
import { renderColorSubject } from 'utils/renderColorSubject';
import { TextSubjectId } from 'app/components/elements/TextSubjectId';
import { BloombassadorButton, PrimaryButton } from 'app/components/elements/ButtonCustom';
import { useNavigate } from 'react-router-dom';

const QUESTION_SIZE = 15;
type QuestionsTabProps = {
  majorId?: string;
};
export const QuestionsTab = ({ majorId }: QuestionsTabProps) => {
  const isMobile = useMediaQuery('(max-width:700px)');
  const navigate = useNavigate();
  const [listQuestions, setListQuestions] = useState<Questions[]>([]);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const fetchDataQuestions = useCallback(async () => {
    const res = await axios.get(`/questions?hasNotAnswer=1&page=${page}&size=${QUESTION_SIZE}`);
    const newData = res.data.result as Questions[];
    if (newData.length > 0) {
      const dataConvert = reject(newData, (obj) => !isEmpty(obj.answer));
      setListQuestions([...listQuestions, ...dataConvert]);
      setPage((prevState) => prevState + 1);
      return;
    }
    setHasMore(false);
  }, [page, listQuestions]);

  const handleDeleteQuestion = useCallback(
    async (id: string) => {
      await axios.put(`/questions/${id}`);
      const newList = listQuestions.filter((item) => item.id !== id);
      setListQuestions(newList);
    },
    [listQuestions]
  );

  const { asyncCallback: handleFetchQuestions } = useAsyncCallback(fetchDataQuestions, []);
  const { asyncCallback: handleNotAnswer } = useAsyncCallback(handleDeleteQuestion, []);

  useEffect(() => {
    handleFetchQuestions();
    // eslint-disable-next-line
  }, []);

  return (
    <Stack maxWidth={'100%'}>
      <TextTitleStyled textAlign={isMobile ? 'center' : 'unset'}>
        These videos need a response from a Bloombassador.
      </TextTitleStyled>
      <InfiniteScroll
        dataLength={listQuestions?.length}
        next={handleFetchQuestions}
        hasMore={listQuestions?.length >= QUESTION_SIZE * (page - 1) ? hasMore : false}
        style={{ overflow: 'none' }}
        loader={<CircularProgress />}>
        {
          <Stack
            width={'100%'}
            alignItems={isMobile ? 'center' : 'unset'}
            gap={isMobile ? 2.5 : 'unset'}>
            {listQuestions?.map((item) => (
              <Stack
                width={{ xs: '100%', md: 'unset' }}
                key={item.id}
                flexDirection={{ xs: 'column', md: 'row' }}
                gap={2.5}
                py={2.5}
                pl={2.5}
                pr={{ xs: 2.5, md: 0 }}
                borderBottom={'1px solid #D7D7D7'}>
                <Stack flexDirection={'row'} flex={3} gap={2.5}>
                  <Stack
                    minWidth={'136px'}
                    maxWidth={'136px'}
                    width={'100%'}
                    maxHeight={'136px'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    flexDirection={'row'}>
                    <ImageUser isCircle url={item.user.avatar} subjects={[item.subject]} />
                  </Stack>
                  <Stack gap={5} flex={1}>
                    <Stack>
                      <TruncateTableText
                        isPadding={false}
                        textProps={{ fontSize: '18px', fontWeight: 700 }}
                        text={item.user.name}
                        colorText={renderColorSubject(item.subject.id)?.color}
                      />
                      <TruncateTableText
                        isPadding={false}
                        textProps={{ fontSize: '24px', fontWeight: 700 }}
                        text={item.title}
                        colorText={renderColorSubject(item.subject.id)?.color}
                      />
                      <Typography
                        color={'#697D21'}
                        fontSize={'16px'}
                        fontWeight={400}
                        lineHeight={'normal'}>
                        {dayjs(item.createdAt).locale('en').format('DD MMMM YYYY')}
                      </Typography>
                    </Stack>
                    <Stack
                      flexDirection={'row'}
                      alignItems={'flex-end'}
                      justifyContent={'space-between'}>
                      <TextSubjectId subjectId={item.subject.id} />
                      {!isMobile && majorId !== item.subject.id && (
                        <ButtonStyled onClick={() => handleNotAnswer(item.id)}>
                          <Stack flexDirection={'row'} gap={1.25} alignItems={'center'}>
                            <TextPrimaryStyled>I don’t know how to answer</TextPrimaryStyled>
                          </Stack>
                        </ButtonStyled>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
                {isMobile && majorId !== item.subject.id && (
                  <ButtonStyled onClick={() => handleNotAnswer(item.id)}>
                    <Stack flexDirection={'row'} gap={1.25} alignItems={'center'}>
                      <TextPrimaryStyled>I don’t know how to answer</TextPrimaryStyled>
                    </Stack>
                  </ButtonStyled>
                )}
                <Stack
                  flexDirection={{ xs: 'column', md: 'row' }}
                  flex={2}
                  justifyContent={'space-between'}
                  alignItems={{ xs: 'flex-start', md: 'unset' }}>
                  <Stack gap={1} ml={0.375} mr={0.875}>
                    <Typography
                      whiteSpace={'pre'}
                      fontWeight={400}
                      fontSize={'16px'}
                      color={'#697D21'}>
                      Time Remaining to Respond
                    </Typography>
                    <CountdownTimer creationDate={item.approvedAt} />
                  </Stack>
                  <Stack alignItems={'flex-end'} justifyContent={'space-between'} gap={1} mt={2}>
                    {!isMobile && majorId !== item.subject.id && (
                      <IconButton
                        sx={{ width: '12px', height: '12px' }}
                        onClick={() => handleNotAnswer(item.id)}>
                        <img alt={'icon_exit'} src={'/icons/icon_exit.svg'} />
                      </IconButton>
                    )}
                    <Stack gap={1.25}>
                      <PrimaryButton
                        style={{ width: 190, borderRadius: '12px' }}
                        label={'View Question'}
                        onClick={() => navigate(`/dashboard/questions/${item.id}`)}
                        isIconFlower
                      />
                      <BloombassadorButton
                        style={{ width: 190 }}
                        label={'Record Response'}
                        onClick={() => navigate(`/dashboard/answer/${item.id}`)}
                        isIconFlower
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            ))}
          </Stack>
        }
      </InfiniteScroll>
    </Stack>
  );
};
const ButtonStyled = styled(Button)(() => ({
  background: '#FE4093 !important',
  boxShadow: '4px 4px 0px 0px #A00043',
  border: '1px solid #FE4093',
  borderRadius: '20px',
  fontSize: '20px',
  height: '32px',
  minWidth: '206px',
  padding: 0
}));
const TextPrimaryStyled = styled(Typography)(() => ({
  color: '#FFF',
  fontSize: '14px',
  fontWeight: 700,
  fontFamily: 'Helvetica',
  whiteSpace: 'pre-line'
}));
const TextTitleStyled = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: '23px',
  color: '#697D21',
  marginBottom: 2.25
}));
